// import React from "react";
// import { useTranslation } from "../context/LanguageContext";

// const LanguageSelector: React.FC = () => {
//   const { language, setLanguage } = useTranslation();

//   return (
//     <div>
//       <select
//         id="language"
//         name="language"
//         value={language}
//         onChange={(e) =>
//           setLanguage(e.target.value as "en" | "fr" | "pt-PT" | "pt-BR")
//         }
//         className="bg-background text-foreground rounded h-10 w-full lg:w-1/2"
//       >
//         <option value="en">🇬🇧 English</option>
//         <option value="fr">🇫🇷 Français</option>
//         <option value="pt-PT">🇵🇹 Português</option>
//         {/* <option value="pt-BR">🇧🇷 Português (Brasil)</option> */}
//       </select>
//     </div>
//   );
// };

// export default LanguageSelector;

import React, { useEffect } from "react";

const LanguageSelector: React.FC = () => {
  useEffect(() => {
    // Check if the script already exists
    if (
      !document.querySelector(
        'script[src="//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"]'
      )
    ) {
      const googleTranslateScript = document.createElement("script");
      googleTranslateScript.src =
        "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit";
      googleTranslateScript.async = true;
      document.body.appendChild(googleTranslateScript);

      // Define the callback function for initialization
      (window as any).googleTranslateElementInit = () => {
        if (!(window as any).translateElementInitialized) {
          new (window as any).google.translate.TranslateElement(
            {
              pageLanguage: "en", // Default language
              includedLanguages: "en,fr,pt", // Supported languages
              layout: (window as any).google.translate.TranslateElement
                .InlineLayout.SIMPLE,
            },
            "google_translate_element" // The div ID where the widget will be rendered
          );
          (window as any).translateElementInitialized = true; // Prevent re-initialization
        }
      };
    }
  }, []);

  return (
    <div
      id="google_translate_element"
      className="w-full"
      style={{ display: "inline-block" }}
    ></div>
  );
};

export default LanguageSelector;
