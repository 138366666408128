import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const AboutTimeline: React.FC = () => {
  const slides = [
    {
      imageUrl: "/assets/images/timeline/Timeline2.jpg",
    },
    {
      imageUrl: "/assets/images/timeline/Timeline3.jpg",
    },
    {
      imageUrl: "/assets/images/timeline/Timeline1.jpg",
    },
  ];

  return (
    <Carousel
      showArrows={true}
      infiniteLoop={true}
      autoPlay={false}
      interval={5000}
      showThumbs={true}
      showStatus={true}
    >
      {slides.map((slide, index) => (
        <div
          key={index}
          className="h-[80vh] bg-cover bg-center p-8"
          style={{ backgroundImage: `url(${slide.imageUrl})` }}
        ></div>
      ))}
    </Carousel>
  );
};

export default AboutTimeline;
