import React, { useEffect } from "react";
import HeaderImage from "../../components/HeaderImage";
import { Link } from "react-router-dom";

const ImpactSection: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("impact");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/impact.jpg" />
      <div className="container mx-auto my-14">
        {/* Header Section */}
        <section className="text-left mb-12" id="impact">
          <p className="text-sm text-foreground mb-4">
            Since its initiation in 2016, LIBSENSE has made substantial progress
            in promoting Open Science in Africa. LIBSENSE has always leveraged
            its strengths to overcome challenges and make a mark in the African
            scholarly landscape. This page showcases seven key impacts LIBSENSE
            has made in diverse areas.
          </p>

          <p className="text-sm text-forebackground">
            Download LIBSENSE Impact Report:{"  "}
            <Link
              to="https://baobab.wacren.net/records/kym89-1p308"
              target="_blank"
              className="text-primary"
            >
              Impact Evidence and Stakeholders' Testimonials from 2016-2024
            </Link>
          </p>
        </section>

        {/* Key Impacts Section */}
        <section className="space-y-12">
          {[
            {
              title: "Community Building",
              content:
                "LIBSENSE has been crucial in fostering community and collaboration among librarians, researchers, and NREN service providers across Africa. LIBSENSE has built strong, growing communities, including LIBSENSE–Discuss, LIBSENSE-Connect and ECR Teams. These communities drive cultural change and institutional and national frameworks to strengthen open science practices. ",
            },
            {
              title: "Empowerment",
              content:
                "In the past nine years, LIBSENSE has empowered over 2000 researchers and librarians by building their confidence and enhancing their skills through 42 workshops, webinars, and community events.",
            },
            {
              title: "Expanding Knowledge",
              content:
                "LIBSENSE has enhanced the understanding of Open Science among the African research community, university leadership, and national policymakers through workshops, webinars, and community events.",
            },
            {
              title: "Mentoring",
              content:
                "LIBSENSE has provided significant mentorship to early career researchers, benefiting over 50 ECRs..",
            },
            {
              title: "Research Practices",
              content:
                "LIBSENSE has influenced researchers' publishing and dissemination decisions and its work and research practices. ",
            },
            {
              title: "Exposure/Influence",
              content:
                "LIBSENSE has expanded the exposure and influence of members through networking and financial support.",
            },
            {
              title: "Laying Foundations",
              content:
                "LIBSENSE has promoted the adoption and implementation of Open Science policies and reshaped the focus and operations of NRENs towards supporting Open Science.",
            },
          ].map((impact, index) => (
            <div
              key={index}
              className="bg-card shadow-md p-6 rounded-lg flex items-start"
            >
              <div className="flex-shrink-0 w-12 h-12 bg-primary rounded-full flex items-center justify-center mr-6">
                {/* Placeholder for Icon */}
                <span className="text-lg font-bold text-primary-foreground">
                  {index + 1}
                </span>
              </div>
              <div>
                <h3 className="text-lg font-semibold text-foreground mb-2">
                  {impact.title}
                </h3>
                <p className="text-foreground/50 text-xs">{impact.content}</p>
              </div>
            </div>
          ))}
        </section>

        {/* Report Section */}
        {/* <section className="mt-12 text-foreground">
       
        </section> */}

        {/* Video Testimonies */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2  mt-10 space-x-4">
          {/* Video 1 */}
          {/* <div className="flex flex-col justify-center items-center gap-2">
            <iframe
              id="kaltura_player"
              src="https://api.kaltura.nordu.net/p/384/sp/38400/embedIframeJs/uiconf_id/23453416/partner_id/384?iframeembed=true&playerId=kaltura_player&entry_id=0_oealzvn5&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_yat2r91k"
              width="100%"
              height="255"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
              title="LIBSENSE Leadership Meeting III Abuja 2024 - video highlight"
            ></iframe>

            <div className="block text-center mb-4 p-2 rounded-md text-foreground transition duration-300">
              Video Testimony
            </div>
          </div> */}

          {/* Video 2 */}
          <div className="flex flex-col justify-center items-center gap-2">
            <iframe
              id="kaltura_player"
              src="https://api.kaltura.nordu.net/p/384/sp/38400/embedIframeJs/uiconf_id/23453416/partner_id/384?iframeembed=true&playerId=kaltura_player&entry_id=0_npcsnamn&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_6loqx7vz"
              width="100%"
              height="355"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
              title="LIBSENSE-Connect II - Abuja 2024_video highlight"
            ></iframe>

            {/* <div className="block text-center mb-4 p-2 rounded-md text-foreground transition duration-300">
              Video Testimony
            </div> */}
          </div>

          {/* Video 3 - YouTube */}
          <div className="flex flex-col justify-center items-center gap-2">
            {/* <iframe
              width="100%"
              height="355"
              src="https://www.youtube.com/embed/GwVJ6R95T4g"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              className="w-full h-64"
            ></iframe> */}
            <iframe
              id="kaltura_player"
              src="https://api.kaltura.nordu.net/p/384/sp/38400/embedIframeJs/uiconf_id/23453416/partner_id/384?iframeembed=true&playerId=kaltura_player&entry_id=0_dx81n1jx&flashvars[streamerType]=auto&amp;flashvars[localizationCode]=en&amp;flashvars[leadWithHTML5]=true&amp;flashvars[sideBarContainer.plugin]=true&amp;flashvars[sideBarContainer.position]=left&amp;flashvars[sideBarContainer.clickToClose]=true&amp;flashvars[chapters.plugin]=true&amp;flashvars[chapters.layout]=vertical&amp;flashvars[chapters.thumbnailRotator]=false&amp;flashvars[streamSelector.plugin]=true&amp;flashvars[EmbedPlayer.SpinnerTarget]=videoHolder&amp;flashvars[dualScreen.plugin]=true&amp;flashvars[hotspots.plugin]=1&amp;flashvars[Kaltura.addCrossoriginToIframe]=true&amp;&wid=0_t2ntkqr2"
              width="100%"
              height="355"
              allowFullScreen
              allow="autoplay *; fullscreen *; encrypted-media *"
              sandbox="allow-downloads allow-forms allow-same-origin allow-scripts allow-top-navigation allow-pointer-lock allow-popups allow-modals allow-orientation-lock allow-popups-to-escape-sandbox allow-presentation allow-top-navigation-by-user-activation"
              title="LIBSENSE RDM Ghana"
            ></iframe>
            {/* <div className="block text-center mb-4 p-2 rounded-md text-foreground transition duration-300">
              Video Testimony
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ImpactSection;
