import { MatomoProvider as OriginalMatomoProvider } from "@datapunt/matomo-tracker-react";
import { MatomoProviderProps } from "@datapunt/matomo-tracker-react/lib/MatomoProvider";

type ExtendedMatomoProviderProps = MatomoProviderProps & {
  children: React.ReactNode;
};

const MatomoProvider: React.FC<ExtendedMatomoProviderProps> = ({
  children,
  ...props
}) => {
  return <OriginalMatomoProvider {...props}>{children}</OriginalMatomoProvider>;
};

export default MatomoProvider;
