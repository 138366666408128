import React, { useEffect } from "react";
import HeaderImage from "../../components/HeaderImage";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const GhanaSymposium: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("ghanasymposium");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const speakers = [
    {
      name: "Dr. Kwaku Afriyie",
      position:
        "Minister for Environment, Science, Technology and Innovation (MESTI)",
      thumbnail: "/assets/images/ghanasym/Dr-Kwaku-Afriyie-01.jpg",
    },
    {
      name: "Keith McMahon MBE",
      position: "British Deputy High Commissioner to Ghana",
      thumbnail: "/assets/images/ghanasym/Keith-McMahon-01-600x674.png",
    },
    {
      name: "Dr. Wilhelmina Quaye",
      position:
        "Director at CSIR-Science and Technology Policy Research Institute (STEPRI)",
      thumbnail: "/assets/images/ghanasym/Wilhemina-Quaye-01.jpg",
    },
    {
      name: "Kathleen Shearer",
      position:
        "Executive Director of COAR (Confederation of Open Access Repositories) ",
      thumbnail: "/assets/images/experts/Kathleen.jpg",
    },
    {
      name: "Melody Boateng",
      position:
        "National Professional Officer, Natural Sciences Sector, UNESCO",
      thumbnail: "/assets/images/ghanasym/Melody-Boateng-01.jpg",
    },
    {
      name: "Lucas Chigabatia",
      position: "CEO, Ghanaian Academic and Research Network (GARNET)",
      thumbnail: "/assets/images/ghanasym/Lucas-01.jpg",
    },
    {
      name: "Dr Mac-Anthony Cobblah",
      position:
        "Chair, Consortium of Academic and Research Libraries in Ghana (CARLIGH)",
      thumbnail: "/assets/images/ghanasym/MacAnthony-01-600x674.png",
    },
    {
      name: "Oliver Boachie",
      position:
        "Special Advisor to the Minister of Environment, Science, Technology and Innovation",
      thumbnail: "/assets/images/ghanasym/Oliver-B-01.jpg",
    },
    {
      name: "Francis Prince Ankrah",
      position: "Deputy Exec. Sec. Ghana Academy of Arts & Science",
      thumbnail: "/assets/images/ghanasym/Fancis-Ankrah-01.jpg",
    },
    {
      name: "Dr. Dominic Agyei Dankwa",
      position: "Researcher, PublicHealth.africa",
      thumbnail: "/assets/images/ghanasym/Dr.-Dominic-01-600x674.png",
    },
    {
      name: "Dr. Thomas Amatey Tagoe",
      position: "Co-Chair, Ghana Young Academy ",
      thumbnail: "/assets/images/ghanasym/Dr.-Thomas-Tagoe-01-600x674.png",
    },
    {
      name: "Omo Oaiya",
      position:
        "Chief Strategy Officer, West and Central African Research and Education Network (WACREN)",
      thumbnail: "/assets/images/ghanasym/Omo-2-01.jpg",
    },
  ];

  const hosts = [
    {
      id: 1,
      thumbnail: "/assets/images/ghanasym/hosts/MESTI-300x300.png",
    },
    {
      id: 2,
      thumbnail: "/assets/images/ghanasym/hosts/garnet_logo-300x73.png",
    },
    {
      id: 3,
      thumbnail:
        "/assets/images/ghanasym/hosts/CARLIGH_mainlogo_v1-300x300.png",
    },
    {
      id: 4,
      thumbnail: "/assets/images/ghanasym/hosts/FCDO-01-300x184.jpg",
    },
  ];

  const partners = [
    {
      id: 1,
      thumbnail: "/assets/images/Wacren-Logo.jpg",
    },
    {
      id: 2,
      thumbnail: "/assets/images/ghanasym/partners/AC3-EU-01-300x184.jpg",
    },
    {
      id: 3,
      thumbnail: "/assets/images/coar-logo.jpg",
    },
    {
      id: 4,
      thumbnail: "/assets/images/EIFL_logo.jpg",
    },
    {
      id: 5,
      thumbnail: "/assets/images/ghanasym/partners/CSIR-STEPRI-300x79.png",
    },
    {
      id: 6,
      thumbnail:
        "/assets/images/ghanasym/partners/brunel-university-logo-colour-300x119.jpg",
    },
    {
      id: 7,
      thumbnail: "/assets/images/ghanasym/partners/MoE-01-300x184.jpg",
    },
    {
      id: 8,
      thumbnail: "/assets/images/ghanasym/partners/GAAS-logo-300x300.png",
    },
    {
      id: 9,
      thumbnail: "/assets/images/ghanasym/partners/UG-legon-logo.png",
    },
    {
      id: 10,
      thumbnail: "/assets/images/ghanasym/partners/KNUST-log.png",
    },
    {
      id: 11,
      thumbnail: "/assets/images/UNESCO.jpg",
    },
    {
      id: 12,
      thumbnail:
        "/assets/images/ghanasym/partners/British-High-Commission-300x200.jpg",
    },
    {
      id: 13,
      thumbnail: "/assets/images/TUOS_PRIMARY_LOGO_FULL-COLOUR.jpg",
    },
    {
      id: 14,
      thumbnail: "/assets/images/coppha/PublicHealth.Africa-PHA.jpg",
    },
    {
      id: 15,
      thumbnail:
        "/assets/images/ghanasym/partners/Ghana-Young-Academy-283x300.png",
    },
  ];

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/ghanasym.jpg" />
      <div className="container mx-auto my-14" id="ghanasymposium">
        <section className="mb-12 text-center">
          <h4 className="text-left text-xl font-semibold text-foreground mb-4 mt-6">
            About the event
          </h4>
          <div className="text-left text-sm text-foreground">
            <p className="mb-4">
              The global irrevocable and free access to quality research and
              data that is Open Science promises to accelerate the rate of
              discoveries and significantly increase the impact of research and
              education and the skills for growth in emerging economies.
            </p>
            <p className="mb-4">
              However, such open scholarship must be adopted to ensure real and
              substantial benefits for research and societies in Ghana. Part of
              the challenge is to understand the local landscape and identify
              strategic opportunities for intervention where contextually
              tailored solutions can be developed and aligned with global
              standards.
            </p>
            <p className="mb-4">
              The science, education and research community of policy-makers,
              practitioners, activists and funders in Ghana gathered to discuss
              a wide array of open science matters, including infrastructure,
              policy and capacity building, at the Movenpick Ambassador Hotel
              Accra on <strong>Wednesday, October 19, 2022</strong>.
            </p>

            <p className="mb-4">
              After successful events in Nigeria and Cote d'Ivoire, the third
              iteration of the LIBSENSE Open Science Roadmaps in Ghana sought
              to:
            </p>

            <ul className="text-sm text-foreground mb-4 list-disc">
              <li className="mb-2">
                Highlight the importance of open science/scholarship and access
                to research in achieving the SDGs and develop a shared vision
                for an open science policy framework for Ghana.
              </li>
              <li className="mb-2">
                Consult with diverse stakeholders and partners to understand the
                landscape and establish a priority-setting process.
              </li>
              <li className="mb-2">
                Identify opportunities for building synergies in the science,
                tech, and innovation ecosystem for open science.
              </li>
              <li className="mb-2">
                Co-create a roadmap for an enabling policy environment for
                Ghana, including priority infrastructure and capacity building
              </li>
            </ul>

            <p className="mb-4">
              The event was organised by{" "}
              <Link
                to="https://wacren.net"
                target="_blank"
                className="text-primary"
              >
                WACREN
              </Link>{" "}
              and jointly hosted by the Ministry of Science, Technology and
              Innovation (MESTI), the Ghanaian Academic and Research Network
              (GARNET) and the Consortium of Academic and Research Libraries in
              Ghana (CARLIGH), with support and funding from the Foreign,
              Commonwealth and Development Office (FCDO) UK.
            </p>
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h4 className="text-left text-xl font-medium text-foreground mb-4 mt-6">
            Speakers
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {speakers &&
              speakers.map((item: any, index) => (
                <motion.div
                  key={item.name}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.name}
                      loading="lazy"
                      className="object-contain w-full h-48 rounded-md"
                    />
                  </div>
                  <div className="flex flex-col justify-between p-4">
                    <h3 className="text-sm font-semibold text-foreground mb-4">
                      {item.name}
                    </h3>
                    <p className="text-xs text-foreground">{item.position}</p>
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h3 className="text-center text-xl font-medium text-foreground mb-4 mt-6">
            Hosts
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {hosts &&
              hosts.map((item: any, index) => (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0 p-4">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.id}
                      loading="lazy"
                      className="object-contain w-full h-16 rounded-md"
                    />
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h3 className="text-center text-xl font-medium text-foreground mb-4 mt-6">
            Partners
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {partners &&
              partners.map((item: any, index) => (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0 p-4">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.id}
                      loading="lazy"
                      className="object-contain w-full h-16 rounded-md"
                    />
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-8">
          <h3 className="text-center text-lg font-semibold text-foreground mb-4">
            Resources
          </h3>
          <div className="flex justify-center space-x-4 mt-8">
            {/* <Link
              to="https://baobab.wacren.net/communities/coppha/records"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Presentations
            </Link> */}
            <Link
              to="https://www.youtube.com/watch?v=VXbX9YehclE"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Video
            </Link>
            <Link
              to="https://photos.wacren.net/index.php?/category/44"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Photos
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default GhanaSymposium;
