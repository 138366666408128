import React, { useEffect } from "react";
import ButtonGroup from "../../components/InvolvedBtn";
import { Link, useLocation } from "react-router-dom";
import HeaderImage from "../../components/HeaderImage";

const ProjectsInitiative: React.FC = () => {
  const { hash } = useLocation();

  useEffect(() => {
    const element = document.getElementById("projects-initiatives");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  useEffect(() => {
    if (hash) {
      const element = document.getElementById(hash.replace("#", ""));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/project.jpg" />

      <div className="container mx-auto my-14" id="projects-initiatives">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          Projects & Initiatives
        </h2>

        <section className="mb-14">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            Diamond OA Publishing in Africa Community of Practice
          </h3>
          <p className="text-sm text-foreground mb-4">
            <Link
              to="https://wacren.net/"
              target="_blank"
              className="text-primary"
            >
              WACREN
            </Link>
            , through the LIBSENSE initiative, the Electronic Information for
            Libraries (
            <Link
              to="https://www.eifl.net/"
              target="_blank"
              className="text-primary"
            >
              EIFL
            </Link>
            ) and the African Journal Online (
            <Link
              to="https://www.ajol.info/index.php/ajol"
              target="_blank"
              className="text-primary"
            >
              AJOL
            </Link>
            ), have established a Community of Practice (CoP) as part of the{" "}
            <Link
              to="https://www.eifl.net/eifl-in-action/collaboration-sustainable-open-access-publishing-africa"
              target="_blank"
              className="text-primary"
            >
              Collaboration for Sustainable OA Publishing in Africa
            </Link>{" "}
            project supported by{" "}
            <Link
              to="https://wellcome.org/"
              target="_blank"
              className="text-primary"
            >
              Wellcome
            </Link>
            . The CoP is designed to build the capacity and facilitate
            collaboration among editors and publishers of no-fee open access
            (OA) journals and books across Africa as they navigate the rapidly
            evolving academic publishing landscape.  It is open to all editors
            and publishers affiliated with African organisations that publish
            no-fee open access journals or books. Community members will share
            experiences and best practices in Diamond OA publishing, build
            collaborations and partnerships to enhance publishing quality and
            help the ongoing momentum in advocating for sustainable and
            equitable OA models.
          </p>

          <Link
            to="/diamond-open-access-community-of-practice"
            className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
          >
            Read more
          </Link>
        </section>

        <section className="mb-14">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            Coalition for Open Access Publishing of Public Health in Africa
            (COPPHA)
          </h3>
          <p className="text-sm text-foreground my-4">
            The{" "}
            <Link
              to="/news/671e0e605f8599b2099f4cc8"
              target="_blank"
              className="text-primary"
            >
              Coalition for Open Access Publishing of Public Health in Africa
              (COPPHA)
            </Link>{" "}
            is an innovative initiative focused on advancing Diamond Open Access
            publishing across Africa. Led by WACREN in collaboration with{" "}
            <Link
              to="https://www.publichealth.africa/"
              target="_blank"
              className="text-primary"
            >
              PublicHealth.Africa (PHA)
            </Link>
            ,{" "}
            <Link
              to="https://peoples-praxis.org/"
              target="_blank"
              className="text-primary"
            >
              Peoples-Praxis
            </Link>
            ,{" "}
            <Link
              to="https://www.afrehealth.org/"
              target="_blank"
              className="text-primary"
            >
              AFREhealth
            </Link>
            , the{" "}
            <Link
              to="https://publichealth-edu.org/"
              target="_blank"
              className="text-primary"
            >
              West African Institute of Public Health (WAIPH)
            </Link>
            , and the{" "}
            <Link
              to="https://journals.ub.umu.se/index.php/shaj"
              target="_blank"
              className="text-primary"
            >
              Somali Health Action Journal
            </Link>
            , COPPHA is committed to promoting equity, accessibility, and
            excellence in publishing. Diamond Open Access publishing, which
            ensures that authors and readers face no financial barriers, is
            central to COPPHA’s mission of equitable research dissemination and
            fostering high-quality, open access publishing practices across the
            continent.
          </p>
          <Link
            to="/coppha"
            className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
          >
            Read more
          </Link>
        </section>

        {/* Section 1: Research Data Management */}
        <section className="mb-14">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            Early Career Researcher (ECR) Initiative
          </h3>
          <p className="text-sm text-foreground mb-4">
            The LIBSENSE Early Career Researcher (ECR) Initiative aims to
            support the development of early-career researchers across Africa by
            fostering skills, resources, and networks critical for research
            success. Focusing on best practices in RDM and research ethics, the
            initiative fosters the formation of ECR Advocacy Groups in different
            African countries. These groups are primarily early-career
            researchers, librarians, research administrators, and academics. The
            LIBSENSE ECR initiative promotes open science and responsible
            research processes to enhance research visibility, transparency, and
            quality across the African continent.
          </p>
          <Link
            to="/ecr"
            className="mt-4 hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground text-center"
          >
            Read more
          </Link>
        </section>

        {/* Section 4: LIBSENSE Network of Experts */}
        <section className="mb-14">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            LIBSENSE Network of Experts
          </h3>
          <p className="text-sm text-foreground mb-4">
            LIBSENSE has established a diverse Network of Experts to support
            open science initiatives across Africa. These experts, with
            knowledge and experience in various disciplines of open science and
            open access, provide guidance on policy development, open access
            repositories, research data management, and more. The network is
            designed to build capacity, offer mentorship, and provide tailored
            support to academic institutions, research organisations, and
            national bodies seeking to implement open science practices.
            Organisations needing expert counsel and resources can connect with
            LIBSENSE for specialised consultation.
          </p>
          <Link
            to="/network-of-experts"
            className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
          >
            Read more
          </Link>
        </section>

        {/* Section 2: Open Science  */}

        {/* Section 3: LIBSENSE-Connect */}
        <section className="mb-14" id="libsense-connect">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            LIBSENSE-Connect
          </h3>
          <p className="text-sm text-foreground mb-2">
            LIBSENSE-Connect is a comprehensive and interactive program designed
            to empower African software and infrastructure developers with the
            knowledge and skills to embrace open science principles and
            practices. The program aims to foster a more inclusive and
            collaborative research ecosystem by building the technical
            capacities to develop infrastructure that promotes transparency,
            accessibility, and reproducibility of research outputs.
          </p>
          <p className="text-sm text-foreground mb-2">
            Through a series of hands-on{" "}
            <Link
              to="https://indico.wacren.net/category/41/"
              target="_blank"
              className="text-primary"
            >
              workshops
            </Link>{" "}
            and expert-led sessions, LIBSENSE-Connect delves into key open
            science topics to offer practical insights into leveraging metadata,
            technical standards, and repository platforms to enhance information
            exchange, discovery, and data sharing. The program emphasises
            adherence to the FAIR principles (Findable, Accessible,
            Interoperable, and Reusable) to create a more connected and
            efficient research landscape.
          </p>
          <p className="text-sm text-foreground mb-4">
            The first{" "}
            <Link
              to="https://indico.wacren.net/event/177/"
              target="_blank"
              className="text-primary"
            >
              workshop in Lagos
            </Link>{" "}
            in 2023 focused on understanding the infrastructural requirements
            needed to meet the needs of the open science community in Africa.{" "}
            <Link
              to="https://baobab.wacren.net/records/t6d47-pje68"
              target="_blank"
              className="text-primary"
            >
              The Abuja Workshop in 2024 centered on intitutional requirement
              for integration into the shared national repository
            </Link>{" "}
            developed by WACREN under the AfricaConnect3 project (co-funded by
            the European Union)
          </p>
          {/*  */}
        </section>

        {/* Section 5: Research Development & Innovation (RDI) */}
        <section className="mb-14">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            Research Development & Innovation (RDI)
          </h3>
          <p className="text-sm text-foreground mb-4">
            LIBSENSE has been involved in advancing research, development and
            innovation (RDI) in the WACREN region. LIBSENSE considers this
            essential to fostering a robust, sustainable, and impactful research
            ecosystem. By integrating RDI efforts into its strategy, LIBSENSE
            supports the growth of scientific collaboration, technological
            advancement, and innovative solutions across the region. LIBSENSE
            collaborated with other partners to organise the{" "}
            <Link
              to="https://www.rdi-coordination.ng/"
              target="_blank"
              className="text-primary"
            >
              National RDI Coordination Summit in Abuja, Nigeria
            </Link>{" "}
            and coordinated a session on the role of open data in transforming
            RDI practices. LIBSENSE is open to collaborating in similar efforts
            in other countries in the WACREN region and beyond.
          </p>
        </section>

        <ButtonGroup />
      </div>
    </>
  );
};

export default ProjectsInitiative;
