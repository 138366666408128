import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderImage from "../../components/HeaderImage";

const SierraLeoneSymposium: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("sierraleonesym");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const speakers = [
    {
      name: "Dr. David Moinina Sengeh",
      position: "Chief Minister, Sierra Leone",
      thumbnail:
        "/assets/images/sierraleonesym/Dr.-David-Moinina-Sengeh-01-600x680.png",
    },
    {
      name: "Dr. Haja Ramatulai Wurie",
      position: "Minister of Technical and Higher Education, Sierra Leone",
      thumbnail:
        "/assets/images/sierraleonesym/Haja-Ramatulai-Wurie-01-600x680.png",
    },
    {
      name: "Salima Mormorna Bah",
      position: "Minister of Communication, Technology & Innovation",
      thumbnail:
        "/assets/images/sierraleonesym/Salima-Mormorna-Bah-600x680.png",
    },
    {
      name: "Prof. Osman Sankoh COR (Mallam O.)",
      position: "Deputy Vice Chancellor, UNIMTECH",
      thumbnail: "/assets/images/sierraleonesym/Prof.-Sankoh-600x680.png",
    },
    {
      name: "Prof Bashiru M Koroma",
      position: "Vice-Chancellor &Principal, Njala University",
      thumbnail: "/assets/images/sierraleonesym/VC-for-Njala-01-600x680.png",
    },
    {
      name: "Yeama Sarah Thompson",
      position: "Executive Dir. & Founder, Initiatives for Media Development",
      thumbnail: "/assets/images/sierraleonesym/Yeama-Thompson-01-600x680.png",
    },
    {
      name: "Dr. Thomas Songu",
      position: "CEO, SLREN & ICT Director, Njala University",
      thumbnail: "/assets/images/sierraleonesym/Thomas-Songu-01-1-600x680.png",
    },
    {
      name: "Michala Mackay",
      position: "Director & COO, Directorate for DSTI, Sierra Leone",
      thumbnail:
        "/assets/images/sierraleonesym/Michala-Mackay-new-01-600x680.png",
    },
    {
      name: "Prof. Aliyageen Mohamed Alghali",
      position: "Chair, Tertiary Education Commission (TEC)",
      thumbnail:
        "/assets/images/sierraleonesym/Prof.-Aliyageen-Mohamed-Alghali-600x680.png",
    },
    {
      name: "Fatmata Kaiwa",
      position:
        "Director, Science Education, Ministry of Technical & Higher Education (MTHE)",
      thumbnail: "/assets/images/sierraleonesym/Fatmatah-new-01-600x680.png",
    },
    {
      name: "Prof Miriam Conteh-Morgan",
      position:
        "LIBSENSE Coordinator & Deputy Vice-Chancellor, IPAM, University of Sierra Leone",
      thumbnail: "/assets/images/sierraleonesym/Miriam-Conteh-01-600x680.png",
    },
    {
      name: "Prof Foday Sahr",
      position: "Vice-Chancellor & Principal, University of Sierra Leone",
      thumbnail:
        "/assets/images/sierraleonesym/Prof.-Foday-Sahr-01-600x680.png",
    },
    {
      name: "Prof Jonas Redwood-Sawyerr",
      position:
        "Chair, SLREN & Chair, National Science, Technology & Innovation Council",
      thumbnail: "/assets/images/sierraleonesym/Prof.-Jonas-01-600x680.png",
    },
    {
      name: "Prof Rev. Edwin JJ Momoh",
      position: "Vice-Chancellor & Principal, EBKUST",
      thumbnail: "/assets/images/sierraleonesym/Edwin-Momoh-01-600x680.png",
    },
    {
      name: "Dr Mohammed Combo Kamanda",
      position:
        "Secretary General of the UNESCO National Commission for Sierra Leone ",
      thumbnail: "/assets/images/sierraleonesym/Mohammed-Kamanda-600x680.png",
    },
    {
      name: "Prof Rashid Ansumana",
      position: "Dean, School of Community Health Sciences, Njala University",
      thumbnail: "/assets/images/sierraleonesym/Rashid-Ansumana-600x680.png",
    },
    {
      name: "Omo Oaiya",
      position:
        "Chief Strategy Officer, West and Central African Research and Education Network (WACREN)",
      thumbnail: "/assets/images/ghanasym/Omo-2-01.jpg",
    },
  ];

  const partners = [
    {
      id: 1,
      thumbnail: "/assets/images/Wacren-Logo.jpg",
    },
    {
      id: 2,
      thumbnail: "/assets/images/ghanasym/hosts/FCDO-01-300x184.jpg",
    },
    {
      id: 3,
      thumbnail: "/assets/images/Wacren-Logo.jpg",
    },
    {
      id: 4,
      thumbnail:
        "/assets/images/sierraleonesym/partners/WhatsApp-Image-2023-08-09-at-1.19.30-AM-300x300.jpeg",
    },
    {
      id: 5,
      thumbnail:
        "/assets/images/sierraleonesym/partners/MTHE-Sierra-Leone-300x300.jpg",
    },
    {
      id: 6,
      thumbnail:
        "/assets/images/sierraleonesym/partners/SLREN-logo-300x300.jpg",
    },
    {
      id: 7,
      thumbnail:
        "/assets/images/sierraleonesym/partners/World-Bank-logo-300x157.jpg",
    },
    {
      id: 8,
      thumbnail: "/assets/images/UNESCO.jpg",
    },
    {
      id: 9,
      thumbnail:
        "/assets/images/sierraleonesym/partners/DSTI-Sierra-Leone-logo-300x300.jpg",
    },
    {
      id: 10,
      thumbnail: "/assets/images/EU-logo.png",
    },
    {
      id: 11,
      thumbnail: "/assets/images/AC3-logo.png",
    },
  ];

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/sierraleonesym.jpg" />
      <div className="container mx-auto my-14" id="sierraleonesym">
        <section className="mb-12 text-center">
          <h4 className="text-left text-xl font-semibold text-foreground mb-4 mt-6">
            About the event
          </h4>
          <div className="text-left text-sm text-foreground">
            <p className="mb-4">
              The Sierra Leone Research and Education Network (
              <Link
                to="https://cvcnigeria.org/"
                target="_blank"
                className="text-primary"
              >
                SLREN
              </Link>
              ) and the Ministry of Technical and Higher Education(
              <Link
                to="https://cvcnigeria.org/"
                target="_blank"
                className="text-primary"
              >
                MTHE
              </Link>
              ), are set to host key stakeholders of science, research and
              innovation to discuss a national approach to open science in the
              West African country.
            </p>
            <h4 className="text-left text-lg font-medium text-foreground">
              Who will be there ?
            </h4>
            <p className="mb-4">
              The symposium will gather diverse stakeholders, including
              representatives from the science, education & research
              communities, policy-makers, library professionals, IT
              practitioners, and funders in Sierra Leone. They will explore
              various open science topics, including infrastructure, policy
              development, and capacity building. This event will begin a
              process towards creating a national open science policy and action
              plan.
            </p>

            <h4 className="text-left text-lg font-medium text-foreground">
              Agenda Highlights:
            </h4>
            <ul className="text-sm text-foreground mb-4">
              <li className="mb-2">
                <strong>Day 1</strong>: Engaging discussions on open science
                matters
              </li>
              <li className="mb-2">
                <strong>Day 2</strong>: Formation of task forces to drive the
                open science agenda
              </li>
            </ul>

            <p className="mb-4">
              The ultimate goal of this symposium is to foster an environment
              conducive to adopting open science practices across Sierra Leone's
              research community. The Ministry of Technical and Higher
              Education's commitment to supporting the National Research and
              Education Network (NREN) and open science initiatives in Sierra
              Leone will be crucial in promoting practical information,
              awareness, education, and stakeholder involvement. This
              collaborative effort is dedicated to making open science an
              integral part of the national agenda.
            </p>

            <h4 className="text-left text-lg font-medium text-foreground">
              Event Details:
            </h4>
            <ul className="text-sm text-foreground mb-4">
              <li className="mb-2">
                <strong>Date</strong>: Wednesday, August 23, and Thursday,
                August 24, 2023
              </li>
              <li className="mb-2">
                <strong>Time</strong>: 09:00 - 17:00 UTC
              </li>
              <li className="mb-2">
                <strong>Venue</strong>: New Brookfields Hotel, Freetown
              </li>
            </ul>
            <p className="mb-4">
              The event was organised by{" "}
              <Link
                to="https://wacren.net"
                target="_blank"
                className="text-primary"
              >
                WACREN
              </Link>
              . The UK Foreign Commonwealth Development Office (FCDO) generously
              funds this important event.
            </p>
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h4 className="text-left text-xl font-medium text-foreground mb-4 mt-6">
            Speakers
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {speakers &&
              speakers.map((item: any, index) => (
                <motion.div
                  key={item.name}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.name}
                      loading="lazy"
                      className="object-contain w-full h-48 rounded-md"
                    />
                  </div>
                  <div className="flex flex-col justify-between p-4">
                    <h3 className="text-sm font-semibold text-foreground mb-4">
                      {item.name}
                    </h3>
                    <p className="text-xs text-foreground">{item.position}</p>
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h3 className="text-center text-xl font-medium text-foreground mb-4 mt-6">
            Hosts & Partners
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {partners &&
              partners.map((item: any, index) => (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0 p-4">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.id}
                      loading="lazy"
                      className="object-contain w-full h-16 rounded-md"
                    />
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-8">
          <h3 className="text-center text-lg font-semibold text-foreground mb-4">
            Resources
          </h3>
          <div className="flex justify-center space-x-4 mt-8">
            <Link
              to="/resources"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Slides
            </Link>
            <Link
              to="https://www.youtube.com/watch?v=459z-lYbJ5U"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Video
            </Link>
            <Link
              to="https://photos.wacren.net/index.php?/category/69"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Photos
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default SierraLeoneSymposium;
