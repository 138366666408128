import { useMutation, useQuery } from "react-query";
import { BASE_API_URL } from "../utils/constants";
import { GET, GETQUERY, POST } from "../utils/http.util";

// Fetch news by page, limit, and year
const fetchAllNews = async (
  year: number,
  page: number = 1,
  limit: number = 10
) => {
  const url = `${BASE_API_URL}libsense/all-news`;
  const response = await GETQUERY(url, year, page, limit, {
    headers: { Accept: "application/json" },
  });
  return response;
};

// Hook for fetching news with optional pagination and year filtering
export const useFetchAllNews = (
  year: number,
  page: number = 1,
  limit: number = 10,
  onError: any
) => {
  return useQuery(
    ["allNews", year, page, limit],
    () => fetchAllNews(year, page, limit),
    {
      onError,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};

const fetchNewsById = async (id: string) => {
  const response = await GET(`${BASE_API_URL}libsense/news/${id}`);
  return response.data;
};

export const useFetchNewsById = (id: string) => {
  return useQuery(["newsDetail", id], () => fetchNewsById(id), {
    enabled: !!id,
    retry: false,
  });
};

const fetchAllEvents = async (
  year: number,
  page: number = 1,
  limit: number = 10
) => {
  const url = `${BASE_API_URL}libsense/all-event`;
  const response = await GETQUERY(url, year, page, limit, {
    headers: { Accept: "application/json" },
  });
  return response;
};

// Hook for fetching news with optional pagination and year filtering
export const useFetchAllEvents = (
  year: number,
  page: number = 1,
  limit: number = 10,
  onError: any
) => {
  return useQuery(
    ["allEvents", year, page, limit],
    () => fetchAllEvents(year, page, limit),
    {
      onError,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
    }
  );
};

const fetchEventById = async (id: string) => {
  const response = await GET(`${BASE_API_URL}libsense/event/${id}`);
  return response.data;
};

export const useFetchEventById = (id: string) => {
  return useQuery(["eventDetail", id], () => fetchEventById(id), {
    enabled: !!id,
    retry: false,
  });
};

const fetchUpcoming = async () => {
  const response = await GET(`${BASE_API_URL}libsense/all-upcoming`);
  return response.data;
};

export const useFetchUpcoming = (onError: any) => {
  return useQuery(["upcoming"], () => fetchUpcoming(), {
    onError,
    refetchOnWindowFocus: false,
    keepPreviousData: true,
  });
};

const getTranslation = async (data: { text: string; targetLang: string }) => {
  const url = `${BASE_API_URL}libsense/translate`;
  const response = await POST(url, data, {
    headers: { Accept: "application/json" },
  });
  return response.data;
};

export const useTranslateHook = (onSuccess: any, onError: any) => {
  return useMutation(getTranslation, {
    onSuccess,
    onError,
  });
};

const fetchPolicy = async ({ queryKey }: any) => {
  const [, selectedFilters] = queryKey; // Extract filters from the query key
  const url = `${BASE_API_URL}policyobservatory/policies`;
  const response = await POST(url, selectedFilters, {
    headers: { Accept: "application/json" },
  });
  return response.data;
};

export const useFetchPolicies = (selectedFilters: any, onError: any) =>
  useQuery(["policies", selectedFilters], fetchPolicy, {
    keepPreviousData: true,
    onError,
    enabled: !!selectedFilters,
  });

const fetchPolicyDetails = async (params: string) => {
  const response = await GET(
    `${BASE_API_URL}policyobservatory/policy/${params}`
  );
  return response.data;
};

export const useFetchPolicyDetails = (params: string) => {
  return useQuery(["policyDetail", params], () => fetchPolicyDetails(params), {
    enabled: !!params,
    retry: false,
  });
};
