import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderImage from "../../components/HeaderImage";

const NigeriaSymposium: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("nigeriasymposium");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const speakers = [
    {
      name: "Prof. Tahir Mamman, SAN, OON",
      position: "Minister of Education",
      thumbnail: "/assets/images/nigeriasym/Professor-Tahir-Mamman-600x680.png",
    },
    {
      name: "Dr Richard Montgomery (CMG)",
      position: "British High Commissioner to Nigeria",
      thumbnail: "/assets/images/nigeriasym/Richard-Montgomery-01-600x680.png",
    },
    {
      name: "Mr. Chris J. Maiyaki",
      position:
        "Acting Executive Secretary, National Universities Commission (NUC)",
      thumbnail: "/assets/images/nigeriasym/mayaki-599x680.jpg",
    },
    {
      name: "Susan Mshana",
      position:
        "Deputy Development Director at the British High Commission in Nigeria",
      thumbnail: "/assets/images/nigeriasym/Susan-Mshana-600x680.png",
    },
    {
      name: "Prof. Yakubu Ochefu",
      position: "Secretary General, CVCNU",
      thumbnail: "/assets/images/nigeriasym/Uchefu-01-600x680.png",
    },
    {
      name: "Prof. Abdullahi Ibrahim Musa",
      position: "VC, Kaduna State University",
      thumbnail: "/assets/images/nigeriasym/Prof.-Musa-01-600x680.png",
    },
    {
      name: "Prof. Chinwe Anunobi",
      position: "CEO, National Library of Nigeria",
      thumbnail: "/assets/images/nigeriasym/Chinwe-Anunobi-600x680.png",
    },
    {
      name: "Prof. Jibril Alhassan",
      position: "Chair, AULNU",
      thumbnail:
        "/assets/images/nigeriasym/Prof.-Jibril-Alhassan-01-600x680.png",
    },
    {
      name: "Dr Enang Moma",
      position: "UNESCO Regional Office Abuja",
      thumbnail: "/assets/images/nigeriasym/Dr-Enang-Moma-600x680.png",
    },
    {
      name: "Owen Iyoha",
      position: "CEO, Eko-Konnect, Nigeria",
      thumbnail: "/assets/images/nigeriasym/Owen-Iyoha-01-600x680.png",
    },
    {
      name: "Dr Tom Olyhoek",
      position:
        "Head, Outreach & Advocacy (DOAJ) & Consultant for African Outreach (DOAB) ",
      thumbnail: "/assets/images/experts/Tom-Olyhoek.png",
    },
    {
      name: "Prof. Conrad Asotie Omonhinmin",
      position: "National Contact Person (Nigeria), OA2020 Global Initiative",
      thumbnail: "/assets/images/nigeriasym/Prof.-Conrad-new-01-600x680.png",
    },
    {
      name: "Omo Oaiya",
      position:
        "Chief Strategy Officer, West and Central African Research and Education Network (WACREN)",
      thumbnail: "/assets/images/ghanasym/Omo-2-01.jpg",
    },
  ];

  const partners = [
    {
      id: 1,
      thumbnail: "/assets/images/libsense.png",
    },
    {
      id: 41,
      thumbnail: "/assets/images/ghanasym/hosts/FCDO-01-300x184.jpg",
    },
    {
      id: 111,
      thumbnail: "/assets/images/Wacren-Logo.jpg",
    },
    {
      id: 2,
      thumbnail: "/assets/images/nigeriasym/partners/CVCNU.png",
    },
    {
      id: 3,
      thumbnail: "/assets/images/nigeriasym/partners/icon-300x300-1.png",
    },
    {
      id: 4,
      thumbnail: "/assets/images/nigeriasym/partners/Eko-Konnect-logo.jpg",
    },
    {
      id: 5,
      thumbnail: "/assets/images/nigeriasym/partners/DOAJ-logo-2-300x97.png",
    },
    {
      id: 6,
      thumbnail: "/assets/images/nigeriasym/partners/doab-300x99.png",
    },
    {
      id: 11,
      thumbnail: "/assets/images/UNESCO.jpg",
    },
    {
      id: 12,
      thumbnail: "/assets/images/AC3-logo.png",
    },
    {
      id: 13,
      thumbnail: "/assets/images/EU-logo.png",
    },
    {
      id: 14,
      thumbnail: "/assets/images/nigeriasym/partners/NgREN-logo-300x245.png",
    },
    {
      id: 15,
      thumbnail:
        "/assets/images/nigeriasym/partners/Screenshot-2023-08-18-at-13.10.09-300x139.png",
    },
  ];

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/nigeriasym.jpg" />
      <div className="container mx-auto my-14" id="ghanasymposium">
        <section className="mb-12 text-center">
          <h4 className="text-left text-xl font-semibold text-foreground mb-4 mt-6">
            About the event
          </h4>
          <div className="text-left text-sm text-foreground">
            <p className="mb-4">
              WACREN and key stakeholders in Nigeria are organising the second
              edition of the LIBSENSE Open Science Symposium in Nigeria. The
              symposium will be held in collaboration with the Committee of
              Vice-Chancellors of Nigerian Universities (
              <Link
                to="https://cvcnigeria.org/"
                target="_blank"
                className="text-primary"
              >
                CVCNU
              </Link>
              ), the Association of University Librarians of Nigerian
              Universities ({" "}
              <Link
                to="https://aulnu.org.ng/"
                target="_blank"
                className="text-primary"
              >
                AULNU
              </Link>
              ), and the Committee of Directors of ICT of Tertiary Institutions
              (ComDICT).
            </p>
            <p className="mb-4">
              The symposium will focus on enhancing open science awareness and
              implementation in Nigeria, anchored by the support and
              coordination of these leaders in higher education. A core focus of
              the symposium is to launch a process to amplify the presence of
              Nigerian journals and books in the Directory of Open Access
              Journals ({" "}
              <Link
                to="https://doaj.org/"
                target="_blank"
                className="text-primary"
              >
                DOAJ
              </Link>
              ) and Directory of Open Access Books ({" "}
              <Link
                to="https://www.doabooks.org/"
                target="_blank"
                className="text-primary"
              >
                DOAB
              </Link>
              ).
            </p>

            <h4 className="text-left text-lg font-medium text-foreground">
              Who will be there ?
            </h4>

            <p className="mb-4">
              This symposium will gather diverse stakeholders, including
              tertiary education leaders, science, technology and innovation
              community representatives, policymakers, IT practitioners, and
              funders in Nigeria.
            </p>

            <h4 className="text-left text-lg font-medium text-foreground">
              Event Details:
            </h4>
            <ul className="text-sm text-foreground mb-4">
              <li className="mb-2">
                <strong>Date</strong>: Wednesday, September 13, and Thursday,
                September 14, 2023
              </li>
              <li className="mb-2">
                <strong>Time</strong>: 09:00 - 17:00 UTC
              </li>
              <li className="mb-2">
                <strong>Venue</strong>: CVC Secretariat, Abuja, Nigeria
              </li>
            </ul>

            <p className="mb-4">
              The event was organised by{" "}
              <Link
                to="https://wacren.net"
                target="_blank"
                className="text-primary"
              >
                WACREN
              </Link>
              , DOAJ and DOAB with the support of the UK Foreign Commonwealth
              Development Office (FCDO).
            </p>
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h4 className="text-left text-xl font-medium text-foreground mb-4 mt-6">
            Speakers
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {speakers &&
              speakers.map((item: any, index) => (
                <motion.div
                  key={item.name}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.name}
                      loading="lazy"
                      className="object-contain w-full h-48 rounded-md"
                    />
                  </div>
                  <div className="flex flex-col justify-between p-4">
                    <h3 className="text-sm font-semibold text-foreground mb-4">
                      {item.name}
                    </h3>
                    <p className="text-xs text-foreground">{item.position}</p>
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h3 className="text-center text-xl font-medium text-foreground mb-4 mt-6">
            Hosts & Partners
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {partners &&
              partners.map((item: any, index) => (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0 p-4">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.id}
                      loading="lazy"
                      className="object-contain w-full h-16 rounded-md"
                    />
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-8">
          <h3 className="text-center text-lg font-semibold text-foreground mb-4">
            Resources
          </h3>
          <div className="flex justify-center space-x-4 mt-8">
            <Link
              to="https://drive.wacren.net/index.php/s/x4KAb5JobpH5PFb?path=%2F"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Slides
            </Link>
            <Link
              to="https://www.youtube.com/live/4AxlXHSk0Mg?si=XUxe9oGEKiUOUDZh"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Videos
            </Link>
            <Link
              to="https://drive.wacren.net/index.php/s/d6cyFyB27GcEk9g"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Photos
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default NigeriaSymposium;
