import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderImage from "../../components/HeaderImage";

const TunisiaSymposium: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("tunisiasymposium");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const speakers = [
    {
      name: "Mme Amel Smaoui Chemli",
      position: "DG CNUDST",
      thumbnail: "/assets/images/tunisiasym/user-shadow.jpg",
    },
    {
      name: "Pr. Mourad Bellassoued",
      position: "Directeur Général de la Recherche Scientifique",
      thumbnail: "/assets/images/tunisiasym/user-shadow.jpg",
    },
    {
      name: "Prof. Abdelmajid Ben Amara",
      position:
        "Secrétaire général de la Fédération des conseils arabes de la recherche scientifique FASRC)",
      thumbnail: "/assets/images/tunisiasym/user-shadow.jpg",
    },
    {
      name: "M. Marin Dacos",
      position:
        "(France) Coordinateur national de la science ouverte auprès de la Directrice générale de la recherche et de l’innovation et de la Directrice générale de l'enseignement supérieur et de l'insertion professionnelle",
      thumbnail: "/assets/images/tunisiasym/user-shadow.jpg",
    },
    {
      name: "M. Jean-François Lutz",
      position:
        "Responsable de la Mission Appui à la Recherche - Université de Lorraine - Direction de la Documentation",
      thumbnail: "/assets/images/tunisiasym/user-shadow.jpg",
    },
    {
      name: "M. Bessem Aamira",
      position: "S/Directeur Informatique CNUDST",
      thumbnail: "/assets/images/tunisiasym/user-shadow.jpg",
    },
    {
      name: "M. Bernard DIONE (Sénégal)",
      position: "Maître de conférences et Directeur CNDST",
      thumbnail: "/assets/images/tunisiasym/user-shadow.jpg",
    },
    {
      name: "M. Eliezer Bisimwa Mwongane (République Démocratique du Congo)",
      position: "Bibliothécaire en Chef ULPGL, GOMA",
      thumbnail: "/assets/images/tunisiasym/user-shadow.jpg",
    },
    {
      name: "Mme Cécile COULIBALY (Côte d'Ivoire)",
      position:
        "S/Directrice en charge des commissions scientifiques à l'Université Virtuelle de Côte d'Ivoire",
      thumbnail: "/assets/images/tunisiasym/user-shadow.jpg",
    },
    {
      name: "M. Zakari Lire",
      position:
        "Conseil Africain et Malgache pour l’Enseignement Supérieur (CAMES)",
      thumbnail: "/assets/images/tunisiasym/user-shadow.jpg",
    },
  ];

  const hosts = [
    {
      id: 1,
      thumbnail: "/assets/images/libsense.png",
    },
    {
      id: 2,
      thumbnail: "/assets/images/tunisiasym/hosts/CNUDST-Logo-300x158.png",
    },
    {
      id: 3,
      thumbnail: "/assets/images/tunisiasym/hosts/Tunisia-flag-300x200.png",
    },
    {
      id: 4,
      thumbnail: "/assets/images/tunisiasym/hosts/Tunisia-Ministry.jpg",
    },
  ];

  const partners = [
    {
      id: 1,
      thumbnail: "/assets/images/Wacren-Logo.jpg",
    },
    {
      id: 2,
      thumbnail: "/assets/images/ghanasym/partners/AC3-EU-01-300x184.jpg",
    },
  ];

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/tunisiasym.jpg" />
      <div className="container mx-auto my-14" id="tunisiasymposium">
        <section className="mb-12 text-center">
          <h4 className="text-left text-xl font-semibold text-foreground mb-4 mt-6">
            About the event
          </h4>
          <div className="text-left text-sm text-foreground">
            <p className="mb-4">
              In November 2021, UNESCO Member States unanimously adopt the
              recommendation on Open Science at the 41st session of the UNESCO
              General Conference. LIBSENSE, as an initiative to create a
              community of practice for Open Science and to advance the adoption
              of Open Science services and infrastructure in Africa, is
              partnering with UNESCO to promote this recommendation at regional,
              national and institutional levels.
            </p>
            <p className="mb-4">
              To this end, the workshop was held to address the main challenges
              and opportunities for the implementation of Open Science in
              Francophone African countries.
            </p>

            <p className="mb-4">Key actors came together to:</p>

            <ul className="text-sm text-foreground mb-4 list-disc">
              <li className="mb-2">consolidate experiences and efforts</li>
              <li className="mb-2">
                propose solutions and actions for Open Science
              </li>
              <li className="mb-2">
                initiate a reflection on national and regional policies, action
                plans and roadmaps for the implementation of the principles of
                Open Science as recommended by UNESCO.
              </li>
            </ul>

            <p className="mb-4">
              The event was organised by{" "}
              <Link
                to="https://wacren.net"
                target="_blank"
                className="text-primary"
              >
                WACREN
              </Link>{" "}
              and jointly hosted by LIBSENSE, CNUDST, and the Ministry of Higher
              Education and Scientific Research of Tunis
            </p>
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h4 className="text-left text-xl font-medium text-foreground mb-4 mt-6">
            Speakers
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {speakers &&
              speakers.map((item: any, index) => (
                <motion.div
                  key={item.name}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.name}
                      loading="lazy"
                      className="object-contain w-full h-48 rounded-md"
                    />
                  </div>
                  <div className="flex flex-col justify-between p-4">
                    <h3 className="text-sm font-semibold text-foreground mb-4">
                      {item.name}
                    </h3>
                    <p className="text-xs text-foreground">{item.position}</p>
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h3 className="text-center text-xl font-medium text-foreground mb-4 mt-6">
            Hosts
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {hosts &&
              hosts.map((item: any, index) => (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0 p-4">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.id}
                      loading="lazy"
                      className="object-contain w-full h-16 rounded-md"
                    />
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h3 className="text-center text-xl font-medium text-foreground mb-4 mt-6">
            Partners
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {partners &&
              partners.map((item: any, index) => (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0 p-4">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.id}
                      loading="lazy"
                      className="object-contain w-full h-16 rounded-md"
                    />
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-8">
          <h3 className="text-center text-lg font-semibold text-foreground mb-4">
            Resources
          </h3>
          <div className="flex justify-center space-x-4 mt-8">
            {/* <Link
              to="https://baobab.wacren.net/communities/coppha/records"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Presentations
            </Link> */}
            <Link
              to="https://www.youtube.com/watch?v=RIEpKfMqteA"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Video
            </Link>
            <Link
              to="https://photos.wacren.net/index.php?/category/45"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Photos
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default TunisiaSymposium;
