import React, { useEffect } from "react";
import HeaderImage from "../../components/HeaderImage";
import { Link } from "react-router-dom";

const LIBSENSEEIFL: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("EIFL");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);
  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/collab.jpg" />

      <div className="max-w-4xl mx-auto mb-12 p-4" id="EIFL">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          Collaborations
        </h2>
        <h3 className="text-sm font-semibold mb-4">
          LIBSENSE-EIFL Collaboration on No-Fee Open Publishing
        </h3>
        <p className="text-sm text-foreground mt-4">
          <Link
            target="_blank"
            to="https://wacren.net/en/"
            className="text-primary"
          >
            WACREN
          </Link>{" "}
          (through LIBSENSE),{" "}
          <Link
            target="_blank"
            to="https://www.eifl.org/"
            className="text-primary"
          >
            EIFL
          </Link>{" "}
          , and{" "}
          <Link
            target="_blank"
            to="https://www.ajol.info/index.php/ajol"
            className="text-primary"
          >
            AJOL
          </Link>{" "}
          (African Journals Online), with support from{" "}
          <Link
            target="_blank"
            to="https://wellcome.org/"
            className="text-primary"
          >
            Wellcome
          </Link>{" "}
          , launched a{" "}
          <Link
            target="_blank"
            to="https://www.eifl.org/eifl-in-action/collaboration-sustainable-open-access-publishing-africa"
            className="text-primary"
          >
            three-year project
          </Link>{" "}
          (2023-2025) to strengthen the quality and sustainability of diamond
          open access (OA) publishing services across Africa.
        </p>

        <p className="text-sm text-foreground my-4">
          The project has identified challenges for diamond OA publishing in
          Africa through community consultation and a landscape study. In 2024,
          the{" "}
          <Link
            target="_blank"
            to="https://www.eifl.net/news/grant-call-strengthening-no-fee-open-access-publishing-africa"
            className="text-primary"
          >
            first of two open calls for proposals
          </Link>{" "}
          for funding to strengthen the quality of diamond OA publishing
          services across the region was issued. The funding process was
          participatory, with input and advice from the African diamond OA
          community. The partners awarded{" "}
          <Link
            to="https://www.eifl.net/news/17-grants-strengthen-no-fee-oa-publishing-africa"
            target="_blank"
            className="text-primary"
          >
            17 grants to open access journals and journal hosting platforms
            across Africa
          </Link>
          .
        </p>

        <p className="text-sm text-foreground my-4">
          WACREN, EIFL, AJOL and CAMES hosted insightful webinars on no-fee open
          access publishing in{" "}
          <Link
            target="_blank"
            to="https://video.wacren.net/media/Diamond+OA+Webiner+%28English%29/0_sd0en9qr/595033"
            className="text-primary"
          >
            Anglophone (video)
          </Link>{" "}
          and{" "}
          <Link
            target="_blank"
            to="https://video.wacren.net/media/Webinaire+LIBSENSE+sur+la+Publication+en+Libre+Acc%C3%A8s/0_3zxxxna1"
            className="text-primary"
          >
            Francophone (video)
          </Link>
          African communities, respectively. Key highlights included
          understanding the impact of no-fee open access journals, exploring
          support provided by the partners, and presenting the Wellcome
          Trust-funded grant call.
        </p>
        <p className="text-sm text-foreground my-4">
          Following on the outcomes of the webinars and the needs of the African
          landscape, the partners of this project have established a community
          of practice for{" "}
          <Link
            target="_blank"
            to="/diamond-open-access-community-of-practice"
            className="text-primary"
          >
            Diamond OA editors and publishers in Africa
          </Link>
          .{" "}
          <Link
            target="_blank"
            to="https://survey.wacren.net/index.php/554888"
            className="text-primary"
          >
            Click here to apply to join the CoP
          </Link>
        </p>
      </div>
    </>
  );
};

export default LIBSENSEEIFL;
