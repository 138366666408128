import React from "react";
import { Link } from "react-router-dom";

const RegionalPage: React.FC = () => {
  return (
    <>
      <h3 className="text-center text-lg font-semibold text-foreground mb-4">
        Regional Leadership Policy Workshops
      </h3>
      <p className="text-sm text-foreground mb-4">
        The LIBSENSE Regional Leadership Workshops converge senior
        decision-makers and leaders from African higher education institutions.
        These workshops focus on advancing open science policies, advocacy, and
        governance within the region. Workshops have been held in{" "}
        <Link
          to="https://event.ubuntunet.net/event/65/"
          target="_blank"
          className="text-primary"
        >
          Zanzibar
        </Link>
        ,{" "}
        <Link
          to="https://indico.wacren.net/event/234/"
          target="_blank"
          className="text-primary"
        >
          Abidjan
        </Link>{" "}
        and{" "}
        <Link
          to="https://indico.wacren.net/event/212/"
          target="_blank"
          className="text-primary"
        >
          Abuja
        </Link>{" "}
        to address some of these pertinent issues. The workshops help
        participants understand how to translate global open science principles,
        such as UNESCO's Open Science Recommendations, into concrete policies
        tailored to their institutional and national contexts. LIBSENSE also
        partners with organisations like AAU, AARU, CRUFAOCI, and SARUA to
        ensure the initiatives are deeply rooted in local and regional needs.
      </p>
      <p className="text-sm text-foreground mb-4">
        LIBSENSE has forged strategic partnerships with UNESCO. LIBSENSE
        participated in the global consultation process for the standard-setting
        instrument to guide the global path for open science.{" "}
        <Link
          to="https://baobab.wacren.net/records/phgbg-v2m30"
          target="_blank"
          className="text-primary"
        >
          Read the statement prepared by LIBSENSE for UNESCO, where the former
          proposed a set of principles and actions for open science indigenous
          to Africa
        </Link>
        .
      </p>
    </>
  );
};

export default RegionalPage;
