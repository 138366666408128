import React, { useEffect } from "react";
import HeaderImage from "../../components/HeaderImage";
import { Link } from "react-router-dom";

const Collaborations: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("collaborations");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const Activitties = [
    {
      title: "LIBSENSE-RUFORUM Cooperation",
      link: "/about-us/collaborations/ruforum",
      thumbnail: "/assets/images/backgrounds/ruforum.jpg",
    },
    {
      title: "LIBSENSE-EIFL Collaboration on No-Fee Open Publishing",
      link: "/about-us/collaborations/eeifl",
      thumbnail: "/assets/images/backgrounds/eifl.jpg",
    },
  ];

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/collab.jpg" />

      <div className="max-w-4xl mx-auto mb-12 p-4" id="collaborations">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          Collaborations
        </h2>

        <p className="text-sm text-foreground mt-4">
          LIBSENSE sees strengthening open science as a collaborative endeavour.
          It leverages the strengths of its partners to advance African
          scholarship. Under the AfricaConnect projects, collaborates with the
          West and Central African Research and Education Network (WACREN),
          Eastern and Southern African Research and Education Network (UbuntuNet
          Alliance), the Arab States Research and Education Network (ASREN), and
          organisations like EIFL (Electronic Information for Libraries) and
          RUFORUM (Regional Universities Forum for Capacity Building in
          Agriculture). Through these collaborations, LIBSENSE coordinates
          efforts to build robust, sustainable infrastructures that support open
          access, research data management (RDM), and knowledge sharing across
          the continent.
        </p>

        <section className="mb-14 mt-8">
          {/* <h3 className="text-xl font-semibold text-foreground mb-4 text-left">
            Activities
          </h3> */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {Activitties.map((activity, index) => (
              <div
                key={index}
                className="flex flex-col lg:flex-row overflow-hidden"
              >
                <div className="w-full lg:w-1/2 flex-shrink-0">
                  <img
                    src={activity.thumbnail}
                    alt={activity.title}
                    className="w-full bg-gray-200 h-full bg-cover"
                  />
                </div>
                {/* Text section */}
                <div className="flex flex-col text-left justify-center p-2 w-full">
                  <h4 className="text-sm font-medium mb-2 text-foreground">
                    {activity.title}
                  </h4>
                  <Link
                    to={activity.link}
                    className="bg-primary text-primary-foreground py-2 px-4 rounded-md w-max text-left"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </section>
      </div>
    </>
  );
};

export default Collaborations;
