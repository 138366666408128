import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ThemeProvider } from "./context/ThemeContext";
import { createInstance } from "@datapunt/matomo-tracker-react";

import { QueryClient, QueryClientProvider } from "react-query";
import MatomoProvider from "./context/matomoConfig";
// import { TranslationProvider } from "./context/LanguageContext";

const instance = createInstance({
  urlBase: "https://webanalytics.wacren.net",
  siteId: 5,
  trackerUrl: "https://webanalytics.wacren.net/matomo.php",
  srcUrl: "https://webanalytics.wacren.net/matomo.php",
  disabled: false,
  heartBeat: {
    active: true,
    seconds: 10,
  },
  linkTracking: false,
  configurations: {
    disableCookies: true,
    setSecureCookie: true,
    setRequestMethod: "POST",
  },
});

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <MatomoProvider value={instance}>
      <QueryClientProvider client={queryClient} contextSharing={true}>
        <ThemeProvider>
          {/* <TranslationProvider> */}
          <div className="bg-background font-poppins selection:bg-primary selection:text-primary-foreground">
            <App />
          </div>
          {/* </TranslationProvider> */}
        </ThemeProvider>
      </QueryClientProvider>
    </MatomoProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
