import axios, { AxiosError } from "axios";
import { BASE_API_URL } from "./constants";

export const axiosInstance = axios.create({
  baseURL: BASE_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 30 * 1000,
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    // Handle error response
    if (error instanceof AxiosError) {
      const errCode = error?.response?.status;
      const errData = error?.response?.data;

      console.log("Error code:", errCode);
      console.log("Error data:", errData);

      if (errCode === 401 || errCode === 403) {
        // Handle unauthorized access
        errData.message = "Your session has expired. Login again.";
        errData.error = "Your session has expired. Login again.";
        window.location.href = "/";
        return Promise.reject(errData);
      }

      if (errCode === 406) {
        const mobileRedirect = errData?.mobileRedirect;
        console.log("Screen to redirect to:", mobileRedirect?.screen);
      }

      if (errCode === 502 || errCode === 500 || errCode === 504 || !errCode) {
        // NotifyUserError({
        //   message: 'An error occured unknown error occured',
        //   detail:
        //     'Please try again later or contact the site admnistrator if this persists.',
        // });
      }

      // Return the response error data
      return Promise.reject(errData);
    }

    console.log("Unexpected error from interceptor:", error?.message);
    return Promise.reject(error); // Reject the original error if no specific handling
  }
);

// == ** client http utils ** == //
export const GET = async (endpoint: string, headers = {}) => {
  return axiosInstance.get(endpoint, { headers });
};

export const GETQUERY = async (
  endpoint: string,
  year: number,
  page: number,
  limit: number,
  headers = {}
) => {
  return axiosInstance.get(endpoint, {
    headers,
    params: { year, page, limit },
  });
};

export const POST = async (endpoint: string, data: any, headers = {}) => {
  return axiosInstance.post(endpoint, data, { headers });
};

export const MULTI_PART_POST = async (
  endpoint: string,
  data: FormData,
  headers = {}
) => {
  headers = {
    ...headers,
    "Content-Type": "multipart/form-data",
  };
  return axiosInstance.post(endpoint, data, { headers });
};

export const PATCH = async (endpoint: string, data: any, headers = {}) => {
  headers = { ...headers };
  return axiosInstance.patch(endpoint, data, { headers });
};

export const MULTI_PART_PATCH = async (
  endpoint: string,
  data: FormData,
  headers = {}
) => {
  headers = {
    ...headers,
    "Content-Type": "multipart/form-data",
  };
  return axiosInstance.patch(endpoint, data, { headers });
};

export const DELETE = async (
  endpoint: string,
  id: string | number,
  headers = {}
) => {
  headers = { ...headers };
  return axiosInstance.delete(`${endpoint}/${id}`, { headers });
};
// == ** client http utils ** == //
