import React, { useState } from "react";
import HeaderImage from "../../components/HeaderImage";
import {
  PolicyElements,
  PolicyRequirements,
  PolicyTypes,
} from "../../utils/data.array";
import { useFetchPolicies } from "../../hooks/all.hooks";
import Checkbox from "../../components/Checkbox";
import { useNavigate } from "react-router-dom";
// import { FaFilter } from "react-icons/fa";
import { motion } from "framer-motion";

type FilterType =
  | "policyType"
  | "policyElement"
  | "policyRequirement"
  | "country";

const PolicyObservatory: React.FC = () => {
  const navigate = useNavigate();

  const [selectedFilters, setSelectedFilters] = useState<{
    policyType: string[];
    policyElement: string[];
    policyRequirement: string[];
    country: string[];
    search: string;
  }>({
    policyType: [],
    policyElement: [],
    policyRequirement: [],
    country: [],
    search: "",
  });

  const [isSidebarOpen] = useState(true);
  const [limit, setLimit] = useState(8);

  const {
    data,
    // isLoading,
    isError,
  } = useFetchPolicies({ ...selectedFilters, page: 1, limit }, (error: any) => {
    console.error("Error fetching policies:", error);
  });

  const policies = data?.policies;
  const pagination = data?.pagination;
  const availableCountries = data?.availableCountries;

  const handleCheckboxChange = (filterType: FilterType, value: string) => {
    setSelectedFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters };
      if (updatedFilters[filterType].includes(value)) {
        updatedFilters[filterType] = updatedFilters[filterType].filter(
          (item) => item !== value
        );
      } else {
        updatedFilters[filterType] = [...updatedFilters[filterType], value];
      }
      return updatedFilters;
    });
  };

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFilters((prev) => ({
      ...prev,
      search: e.target.value,
    }));
  };

  const handleReadMore = (params: string) => {
    navigate(`/about-us/policy-observatory/${params}`);
  };

  const loadMore = () => {
    setLimit((prev) => prev + 8);
  };

  // const toggleSidebar = () => {
  //   setIsSidebarOpen(!isSidebarOpen);
  // };

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/observatory.jpg" />

      <div className="container mx-auto px-4 mb-14" id="policy-observatory">
        <h2 className="text-center text-xxl font-semibold text-foreground uppercase my-4">
          Open Science Observatory
        </h2>
        <p className="text-sm text-foreground mb-6">
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Hic saepe,
          rem numquam iste eum eveniet dolor quos consequuntur quo odit? Vitae,
          ratione dolorum. Aperiam, praesentium reprehenderit. Exercitationem,
          nam.
        </p>

        <div className="flex flex-col lg:flex-row lg:h-screen gap-4">
          {/* Sidebar with checkboxes */}
          <div
            className={`${
              isSidebarOpen ? "block" : "hidden lg:block"
            } w-full lg:w-1/4 transition-all duration-300 p-4 bg-background rounded-lg shadow-lg scrollbar-thumb-rounded scrollbar-track-rounded overflow-auto scrollbar-thin scrollbar-thumb-white scrollbar-track-gray-100 dark:scrollbar-thumb-night-700 dark:scrollbar-track-night-800`}
          >
            {/* Toggle Button for Sidebar */}
            {/* <button onClick={toggleSidebar} className="text-xl">
              <FaFilter color="gray" />
            </button> */}

            {/* Filter Options */}
            <div className="mb-2">
              <input
                type="text"
                value={selectedFilters.search}
                onChange={handleSearchChange}
                placeholder="Search Policies..."
                className="w-full p-2 mb-4 border rounded-md"
              />
            </div>

            <div className="mb-2">
              <h3 className="font-medium text-sm mb-2">Country</h3>
              <div className="h-32 scrollbar-thumb-rounded scrollbar-track-rounded overflow-auto transition-all duration-300 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
                {availableCountries?.map((type: any) => (
                  <Checkbox
                    key={type}
                    label={type}
                    check={selectedFilters.country.includes(type)}
                    setCheck={() => handleCheckboxChange("country", type)}
                  />
                ))}
              </div>
            </div>

            <div className="mb-4">
              <h3 className="font-medium text-sm mb-2">Policy Type</h3>
              <div className="h-32 scrollbar-thumb-rounded scrollbar-track-rounded overflow-auto transition-all duration-300 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
                {PolicyTypes.map((type) => (
                  <Checkbox
                    key={type.value}
                    label={type.label}
                    check={selectedFilters.policyType.includes(type.value)}
                    setCheck={() =>
                      handleCheckboxChange("policyType", type.value)
                    }
                  />
                ))}
              </div>
            </div>

            <div className="mb-4">
              <h3 className="font-medium text-sm mb-2">Policy Elements</h3>
              <div className="h-32 scrollbar-thumb-rounded scrollbar-track-rounded overflow-auto transition-all duration-300 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
                {PolicyElements.map((type) => (
                  <Checkbox
                    key={type.value}
                    label={type.label}
                    check={selectedFilters.policyElement.includes(type.value)}
                    setCheck={() =>
                      handleCheckboxChange("policyElement", type.value)
                    }
                  />
                ))}
              </div>
            </div>

            <div className="mb-4">
              <h3 className="font-medium text-sm mb-2">Policy Requirement</h3>
              <div className="h-32 scrollbar-thumb-rounded scrollbar-track-rounded overflow-auto transition-all duration-300 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500">
                {PolicyRequirements.map((type) => (
                  <Checkbox
                    key={type.value}
                    label={type.label}
                    check={selectedFilters.policyRequirement.includes(
                      type.value
                    )}
                    setCheck={() =>
                      handleCheckboxChange("policyRequirement", type.value)
                    }
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Policies List */}
          <div className="ml-2 grid h-screen flex-grow content-start">
            <div className="scrollbar-thumb-rounded scrollbar-track-rounded overflow-y-scroll overflow-x-hidden rounded-lg bg-white shadow transition-all duration-300 scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-500 dark:bg-night-700 dark:scrollbar-thumb-night-500 dark:scrollbar-track-night-600">
              {isError && <div>Failed to load policies.</div>}
              {policies && policies.length > 0 ? (
                // <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                policies.map((policy: any) => (
                  <motion.div
                    key={policy._id}
                    className="p-4 bg-card rounded-md shadow-sm mb-2"
                    whileHover={{
                      scale: 1.03,
                      boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.2)",
                    }}
                    whileTap={{ scale: 0.98 }}
                    transition={{ duration: 0.2 }}
                  >
                    <h2 className="text-lg font-bold text-foreground">
                      {policy.policyTitle}
                    </h2>
                    <p className="text-primary text-xxs font-bold">
                      {policy?.organization}
                    </p>
                    <p
                      className="text-xm text-card-foreground/50"
                      dangerouslySetInnerHTML={{
                        __html: policy.policyDescription?.slice(0, 100),
                      }}
                    ></p>
                    <button
                      onClick={() => handleReadMore(policy.policyTitle)}
                      className="text-primary mt-2"
                    >
                      Read more
                    </button>
                  </motion.div>
                ))
              ) : (
                // </div>
                <div className="rounded-sm border border-stroke bg-white px-5 py-10 shadow-default dark:border-strokedark dark:bg-boxdark sm:py-20">
                  <div className="mx-auto max-w-[410px]">
                    <img
                      src="/assets/images/animation/illustration.svg"
                      alt="illustration"
                    />
                    <div className="mt-7.5 text-center">
                      <h2 className="mb-3 text-sm font-bold text-foreground">
                        Sorry, the item can’t be found
                      </h2>
                      <p className="text-xs text-foreground/50 font-medium">
                        The item you were looking for appears to have been
                        moved, deleted or does not exist.
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {pagination?.hasNextPage && (
              <div className="flex justify-between items-center mt-2 m-auto">
                <button
                  onClick={loadMore}
                  className="hover:bg-primary-600 flex-none bg-primary px-4 py-2 text-primary-foreground"
                >
                  Load More
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PolicyObservatory;
