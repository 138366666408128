import React from "react";
import SharedRepositories from "../../components/SharedRepositories";
import HeaderImage from "../../components/HeaderImage";

const SharedPublishing: React.FC = () => {
  const handleDocumentClick = () => {
    window.open("https://publishnow.wacren.net/", "_blank");
  };

  return (
    <>
      <HeaderImage image="/assets/images/services/publishnow-bg.jpg" />

      <div className="min-h-screen ">
        <SharedRepositories
          title="PUBLISHNOW - PUBLISHING PLATFORM"
          paragraphs={[
            "PublishNow is a platform designed to facilitate the entire publishing process. It offers a streamlined workflow for managing submissions, peer reviews, and the publication of scholarly works. PublishNow enables researchers and institutions to manage the publishing lifecycle efficiently, from submission to final publication, ensuring high-quality output and a smooth user experience.",
          ]}
          buttons={[
            {
              label: "Read More",
              onClick: handleDocumentClick,
              variant: "default",
            },
          ]}
        />
      </div>
    </>
  );
};

export default SharedPublishing;
