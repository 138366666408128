import React from "react";
import HeaderImage from "../HeaderImage";
import { Link } from "react-router-dom";

const RuforumPage: React.FC = () => {
  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/collab.jpg" />
      <section className="container mx-auto">
        <h2 className="mt-4 text-xxl font-bold text-foreground mb-4">
          LIBSENSE-RUFORUM Cooperation
        </h2>
        <div className="grid grid-cols-1 gap-8 text-left text-sm text-foreground">
          <div>
            <p className="text-sm text-foreground mt-4">
              <Link
                target="_blank"
                to="https://wacren.net/en/"
                className="text-primary"
              >
                WACREN
              </Link>{" "}
              (on behalf of LIBSENSE) and{" "}
              <Link
                target="_blank"
                to="https://www.ruforum.org/"
                className="text-primary"
              >
                RUFORUM
              </Link>{" "}
              initially signed a{" "}
              <Link target="_blank" to="/ruforum" className="text-primary">
                three-year memorandum of understanding (2021-2023)
              </Link>{" "}
              to enhance African scientific and scholarly communication through
              the{" "}
              <Link
                target="_blank"
                to="https://africaconnect3.net/"
                className="text-primary"
              >
                AfricaConnect3
              </Link>{" "}
              project. Under this MoU, the parties agreed to collaborate in
              LIBSENSE to provide contemporary platforms for agriculture
              research data sharing and open access publishing.{" "}
              <Link
                target="_blank"
                to="https://wacren.net/en/news/ruforum-and-wacren-renew-cooperation-to-expand-open-science-and-digital-services-in-africa/"
                className="text-primary"
              >
                This MoU was renewed in 2023
              </Link>{" "}
              , spanning another two years. The key focal areas are capacity
              building in research data management (RDM), development of open
              access repositories (OARs), promoting open science policies and
              enhancing research impact.
            </p>
          </div>
          <div>
            {/* <h3 className="text-lg font-semibold text-primary mb-4">
              <Link
                to="https://survey.wacren.net/index.php/516664"
                target="_blank"
              >
                Needs Assessment Survey
              </Link>
            </h3> */}
            <p className="mb-4">
              As part of a collaborative agreement between RUFORUM and LIBSENSE,
              this project will address some of RUFORUM’s ICT objectives by
              supporting contemporary platform development to enable
              agricultural research data sharing and management.
            </p>
            <p className="mb-10">
              LIBSENSE and RUFORUM conducted a Needs Assessment Survey. The
              survey sought to gather requirements for an open data repository
              by surveying potential end users within the member institutions of
              RUFORUM.
            </p>
            {/* <Link
              to="https://survey.wacren.net/index.php/516664"
              target="_blank"
              className="hover:bg-primary-600 flex-none bg-primary uppercase px-4 py-2 text-primary-foreground my-10"
            >
              Take Part in the survey
            </Link> */}
          </div>
        </div>
        <div className="grid grid-cols-1 gap-8 text-left text-sm text-foreground mt-6">
          <div>
            <h3 className="text-lg font-semibold text-foreground">
              Survey Validation Workshop
            </h3>

            <div className="flex items-center justify-center mb-14">
              <img
                className=" bg-cover"
                src="/assets/images/workshop/WORKSHOP-UPDATE.jpg"
                alt="workshop"
              />
            </div>

            <p className="mb-4">
              The{" "}
              <Link
                to="https://ruforum.org/AGM2023/sites/default/files/Concept%20note%20RUFORUM-LIBSENSE%20RDM.pdf"
                target="_blank"
                className="text-primary"
              >
                Workshop
              </Link>{" "}
              was organised on the sidelines of the RUFORUM AGM 2022. The
              workshop's key objective was to validate the needs assessment
              survey instrument for the development of an open data and
              knowledge repository for the RUFORUM community. A key outcome was
              the identification of the need to raise awareness about key
              concepts in the survey document.
            </p>
          </div>
          <div>
            <h3 className="text-lg font-semibold text-foreground my-10">
              Survey Validation Workshop
            </h3>

            <div className="flex items-center justify-center mb-14">
              <img
                className=" bg-cover"
                src="/assets/images/workshop/Workshop-Webinar-2.jpg"
                alt="workshop"
              />
            </div>

            <p className="mb-4">
              This{" "}
              <Link
                to="https://survey.wacren.net/index.php/516664"
                target="_blank"
                className="text-primary"
              >
                webinar
              </Link>{" "}
              sensitised participants to key concepts related to open science
              and research data management, which underpin the LIBSENSE-RUFORUM
              needs assessment survey for developing an open data and knowledge
              repository for the RUFORUM community. The webinar provided a
              platform for launching the survey to the community.
            </p>
            {/* <div className="flex justify-center space-x-4 mt-36 mb-20">
              <Link
                to="/assets/pdf/LIBSENSE-RUFORUM-Concept-note_20012023-1.pdf"
                target="_blank"
                className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg hover:text-primary"
              >
                Concept
              </Link>
              <Link
                to="https://youtu.be/vQXiMVdMgCA"
                target="_blank"
                className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg hover:text-primary"
              >
                Video
              </Link>
              <Link
                to="https://drive.wacren.net/index.php/s/iwjiTy3QPcYpXEM"
                target="_blank"
                className="border border-gray-400 text-gray-700 py-2 px-4 rounded-lg hover:text-primary"
              >
                Slides
              </Link>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
};

export default RuforumPage;
