// export const BASE_API_URL = "https://pidslinkapi.ren.africa/api/";
export const BASE_API_URL = "http://localhost:8080/api/";

export const GOOGLETRACKERKEY = "G-981LQ7JTG4";

//

//Admin
const clientToken = "W_L_CT";

export const storage_constants = {
  clientToken,
};
