import React, { useState } from "react";
import { FaThLarge, FaList } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useFetchAllNews } from "../../../hooks/all.hooks";

const NewsSection = () => {
  const navigate = useNavigate();
  const currentYear = new Date().getFullYear();
  const [viewMode, setViewMode] = useState("list");
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [page, setPage] = useState(1);
  const limit = 6;

  // Fetch news data and handle error
  const { data, isLoading, isError } = useFetchAllNews(
    selectedYear,
    page,
    limit,
    (error: any) => {
      console.log("error fetching: ", error);
      // showErrorToast({ message: error.message || 'Failed to fetch news data.' });
    }
  );

  const newsItems = data?.data?.news || [];
  const years = data?.data?.years || [currentYear];
  const totalPages = data?.data?.pagination?.totalPages || 1;

  const handleYearChange = (year: number) => {
    setSelectedYear(year);
    setPage(1);
  };

  const handlePageChange = (newPage: number) => {
    if (newPage > 0 && newPage <= totalPages) {
      setPage(newPage);
    }
  };

  const handleReadMore = (id: string) => {
    navigate(`/news/${id}`);
  };

  return (
    <div className="max-w-5xl mx-auto mb-8 pt-24 px-4 sm:px-6 lg:px-8">
      {/* News Title */}
      <div className="flex flex-col sm:flex-row justify-between items-center mb-4">
        <div className="mb-4 sm:mb-0">
          <h2 className="text-xxxl font-bold text-foreground">News</h2>
          {/* <p className="text-sm text-foreground">
            Stay updated with the latest news on open science.
          </p> */}
        </div>
        <div className="flex space-x-4">
          <FaList
            className={`text-2xl cursor-pointer ${viewMode === "list" ? "text-black" : "text-gray-400"}`}
            onClick={() => setViewMode("list")}
          />
          <FaThLarge
            className={`text-2xl cursor-pointer ${viewMode === "grid" ? "text-black" : "text-gray-400"}`}
            onClick={() => setViewMode("grid")}
          />
        </div>
      </div>

      {/* Loading, Error, or News Items */}
      {isLoading ? (
        <p>Loading news...</p>
      ) : isError ? (
        <p className="text-red-500">Error loading news:</p>
      ) : (
        <>
          <div
            className={
              viewMode === "grid"
                ? "grid grid-cols-1 sm:grid-cols-2 gap-8"
                : "space-y-8"
            }
          >
            {newsItems.map((item: any) => (
              <div
                key={item._id}
                className={`flex ${viewMode === "list" ? "flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4" : "flex-col"} border rounded-lg shadow-md`}
              >
                <div
                  className={`${viewMode === "list" ? "w-full sm:w-96 h-52" : "w-full h-72"} bg-gray-300 rounded-md flex-shrink-0`}
                >
                  <img
                    src={
                      item.imageUrl ? item.imageUrl : "/assets/images/logo.svg"
                    }
                    alt={item.title}
                    loading="lazy"
                    className="object-fill md:object-cover w-full h-full rounded-md"
                  />
                </div>
                <div
                  className={`flex flex-col justify-between p-4 ${viewMode === "list" ? "" : "mt-4"}`}
                >
                  <h3 className="text-sm font-semibold text-foreground mb-4">
                    {item.title}
                  </h3>
                  <p className="text-xs text-foreground">
                    {new Date(item.date).toLocaleDateString()}
                  </p>
                  <div className="flex justify-between items-center mt-2">
                    <button
                      onClick={() => handleReadMore(item._id)}
                      className="hover:bg-primary-600 flex-none rounded-md bg-primary px-4 py-2.5 text-xs font-semibold text-primary-foreground"
                    >
                      Read More
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Pagination */}
          <div className="flex flex-col sm:flex-row justify-between items-center mt-6">
            <button
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
              className="px-4 py-2 bg-gray-300 text-white rounded mb-4 sm:mb-0"
            >
              Previous
            </button>
            <span className="mb-4 sm:mb-0">
              Page {page} of {totalPages}
            </span>
            <button
              onClick={() => handlePageChange(page + 1)}
              disabled={page >= totalPages}
              className="px-4 py-2 text-primary-foreground bg-primary rounded"
            >
              Next
            </button>
          </div>

          {/* Year Navigation */}
          <div className="mt-8">
            <h4 className="text-sm font-bold text-foreground mb-4 rounded">
              News Archives
            </h4>
            <div className="flex flex-wrap gap-4">
              {years.map((year: number) => (
                <button
                  key={year}
                  onClick={() => handleYearChange(year)}
                  className={`border ${selectedYear === year ? "bg-primary text-primary-foreground" : "border-gray-400 text-gray-700"} py-2 px-4 rounded-lg`}
                >
                  {year}
                </button>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default NewsSection;
