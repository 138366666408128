import React, { useEffect, useState } from "react";
import { useFetchPolicyDetails } from "../../hooks/all.hooks";
import { Link, useNavigate, useParams } from "react-router-dom";
import DetailsBackground from "../../components/NewsDetailsBg";

const PolicyObservatoryDetails: React.FC = () => {
  const [isSidebarOpen] = useState(true);

  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();

  const { data, isLoading, isError } = useFetchPolicyDetails(id as string);

  const policyDetail = data?.policy;

  useEffect(() => {
    if (isError) {
      navigate("/not-found");
    }
  }, [isError, navigate]);

  if (isLoading) return <p>Loading...</p>;

  if (isError) {
    // Optional: You can keep a fallback UI here temporarily before redirecting
    return null;
  }

  return (
    <>
      <DetailsBackground title={policyDetail?.policyTitle || "more details"} />
      <div className="container mx-auto px-4 mb-14" id="policy-observatory">
        <div className="bg-card w-full rounded-lg shadow-lg p-4 mb-4 border-b-transparent">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
            <div className="flex flex-col gap-2 mb-4 items-center border-r-2 border-primary">
              <p className="font-bold text-foreground text-sm">Policy Type</p>
              <ul className="grid grid-cols-1">
                {policyDetail?.policyType.map((policy: any, index: number) => (
                  <li className="text-card-foreground/50 text-xs" key={index}>
                    {policy?.value}
                  </li>
                ))}
              </ul>
            </div>

            <div className="flex flex-col gap-2 mb-4 items-center border-r-2 border-primary">
              <p className="font-bold text-foreground text-sm">
                Policy Element
              </p>
              <ul className="grid grid-cols-1">
                {policyDetail?.policyElement.map(
                  (policy: any, index: number) => (
                    <li className="text-card-foreground/50 text-xs" key={index}>
                      {policy?.value}
                    </li>
                  )
                )}
              </ul>
            </div>

            <div className="flex flex-col gap-2 mb-4 items-center">
              <p className="font-bold text-foreground text-sm">
                Policy Requirements
              </p>
              <ul className="grid grid-cols-1">
                {policyDetail?.policyRequirement.map(
                  (policy: any, index: number) => (
                    <li className="text-card-foreground/50 text-xs" key={index}>
                      {policy?.value}
                    </li>
                  )
                )}
              </ul>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-3 gap-4">
          <div className="ml-2 grid content-start lg:col-span-2">
            <p
              className="text-sm text-card-foreground news-content"
              dangerouslySetInnerHTML={{
                __html: policyDetail.policyDescription,
              }}
            ></p>
            <div className="mt-4">
              <p>{/* apply to add policy for your country */}</p>
            </div>
          </div>
          <div
            className={`${
              isSidebarOpen ? "block" : "hidden lg:block"
            } w-full transition-all duration-300 p-6 scrollbar-thumb-rounded scrollbar-track-rounded overflow-auto scrollbar-thin scrollbar-thumb-white scrollbar-track-gray-100 dark:scrollbar-thumb-night-700 dark:scrollbar-track-night-800 lg:col-span-1`}
          >
            <div className="flex flex-col gap-2 mb-4">
              <p className="font-bold text-foreground text-sm">
                Policy Website:
              </p>
              <Link
                to={policyDetail.policyLink}
                target="_blank"
                rel="noreferrer"
                className="text-primary text-xs"
              >
                {policyDetail.policyLink}
              </Link>
            </div>
            {policyDetail?.policyAdoptionDate && (
              <div className="flex gap-2 mb-4 items-center">
                <p className="font-bold text-foreground text-sm">
                  Policy Adoption Date:
                </p>
                <p className="text-foreground/50 text-xs">
                  {new Date(
                    policyDetail.policyAdoptionDate
                  ).toLocaleDateString()}
                </p>
              </div>
            )}
            {policyDetail?.policyEffectiveDate && (
              <div className="flex gap-2 mb-4 items-center">
                <p className="font-bold text-foreground text-sm">
                  Policy Effective Date:
                </p>
                <p className="text-foreground/50 text-xs">
                  {new Date(
                    policyDetail.policyEffectiveDate
                  ).toLocaleDateString()}
                </p>
              </div>
            )}
            <div className="flex gap-2 mb-4 items-center">
              <p className="font-bold text-foreground text-sm">Country:</p>
              <p className="text-foreground/50 text-xs">
                {policyDetail?.country}
              </p>
            </div>
            <div className="flex gap-2 mb-4 items-center">
              <p className="font-bold text-foreground text-sm">Organization:</p>
              <p className="text-foreground/50 text-xs">
                {policyDetail?.organization}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PolicyObservatoryDetails;
