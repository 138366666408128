import React, { useEffect } from "react";
import HeaderImage from "../../components/HeaderImage";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faVideo,
  faEnvelope,
  // faComments,
} from "@fortawesome/free-solid-svg-icons";

const DiamonAccessPage: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("diamond");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/diamond.jpg" />

      <div className="max-w-4xl mx-auto mb-12 p-4" id="diamond">
        {/* <h2 className="text-center text-xxl font-semibold text-foreground uppercase mb-4">
          Coalition for Open Access Publishing of Public Health in Africa
          (COPPHA)
        </h2> */}

        <section className="mb-4 mt-8">
          <h3 className="text-left text-sm font-medium mb-1">
            Welcome to the Diamond Open Access Publishing Community of Practice
            (CoP) for Africa!
          </h3>

          <p className="text-sm text-foreground mb-2">
            You are about to join a vibrant network of over 100 African no-fee
            journal editors united by a shared commitment to advancing Diamond
            Open Access (OA) publishing across the continent and globally.
          </p>
          <p className="text-sm text-foreground mb-2">
            As a member of this CoP, you will have the opportunity to:
          </p>
          <ul className="text-sm text-foreground mb-4 list-disc">
            <li className="mb-2">Share experiences and best practices.</li>
            <li className="mb-2">
              Explore collaboration and partnership opportunities.
            </li>
            <li className="mb-2">
              Advocate collectively for no-fee OA publishing (commonly known as
              Diamond OA).
            </li>
          </ul>
          <p className="text-sm text-foreground my-4">
            The CoP is moderated by the West and Central African Research and
            Education Network (WACREN) and is part of the ‘
            <Link
              to="https://www.eifl.net/eifl-in-action/collaboration-sustainable-open-access-publishing-africa"
              target="_blank"
              className="text-primary"
            >
              Collaboration for Sustainable OA Publishing in Africa
            </Link>
            ’ project, implemented by <strong>EIFL</strong>,{" "}
            <strong>AJOL</strong>, and <strong>WACREN</strong>, with generous
            support from <strong>Wellcome</strong>.
          </p>

          <h4 className="text-left text-sm font-semibold text-foreground">
            Community Activities Include:
          </h4>
          <ul className="text-sm text-foreground mb-4 list-disc">
            <li className="mb-2">
              A dedicated mailing list for ongoing discussions and updates.
            </li>
            <li className="mb-2">
              Monthly virtual meetings to address key topics and foster
              collaboration.
            </li>
          </ul>

          <h4 className="text-sm text-foreground">
            The <strong>first meeting</strong> is scheduled for{" "}
            <span className="text-red-500">22nd January 2025 at 10:00 UTC</span>
            . Topics to be discussed include:
          </h4>
          <ul className="text-sm text-foreground mb-4 list-disc">
            <li className="mb-2">
              Training for editors, reviewers, and authors.
            </li>
            <li className="mb-2">
              The role and limitations of AI tools in scholarly publishing.
            </li>
            <li className="mb-2">
              Recognising the contributions of editors and peer reviewers.
            </li>
            <li className="mb-2">
              Join us today and be part of shaping the future of Diamond OA
              publishing in Africa!{" "}
              <Link
                to="https://survey.wacren.net/index.php/554888"
                target="_blank"
                className="text-primary"
              >
                Click here to apply
              </Link>
              .
            </li>
          </ul>

          {/* Platforms Section */}
          <div className="text-center mt-6">
            <h4 className="text-xl font-semibold text-primary mb-6">
              Community Channels
            </h4>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {/* Monthly Meeting */}
              <Link
                to="https://wacren.zoom.us/meeting/register/u5IsdOGgrT0vG9UqJUXVCBB5UwxRnZsLk3-a"
                target="_blank"
                className="bg-card p-3 rounded-lg shadow-md transform transition-transform hover:scale-105 hover:shadow-lg hover:bg-transparent"
              >
                <div className="text-primary text-xxxxl mb-4">
                  <FontAwesomeIcon icon={faVideo} />
                </div>
                <h3 className="font-semibold text-lg text-card-foreground">
                  Monthly Zoom Meeting
                </h3>
                {/* <p className="text-sm text-foreground mb-2">
                  Join monthly Zoom meetings
                </p> */}
              </Link>

              {/* Mailing List */}
              <Link
                to="https://groups.google.com/u/1/g/diamond-oa-community"
                target="_blank"
                className="bg-card p-3 rounded-lg shadow-md transform transition-transform hover:scale-105 hover:shadow-lg hover:bg-transparent"
              >
                <div className="text-primary text-xxxxl mb-4">
                  <FontAwesomeIcon icon={faEnvelope} />
                </div>
                <h3 className="text-card-foreground font-semibold text-lg hover:text-foreground">
                  Mailing Discussion Forum
                </h3>
                {/* <p className="text-sm text-foreground mb-2">
                  Send the list for others to join
                </p> */}
              </Link>

              {/* Chat Server */}
              {/* <Link
                to="https://matrix.to/#/#diamond-oa-community:matrix.ren.africa"
                target="_blank"
                className="bg-card p-6 rounded-lg shadow-md transform transition-transform hover:scale-105 hover:shadow-lg hover:bg-blue-100"
              >
                <div className="text-primary text-xxxl mb-4">
                  <FontAwesomeIcon icon={faComments} />
                </div>
                <h3 className="font-semibold text-lg mb-2">Chat Server</h3>
                <p className="text-sm text-foreground mb-2">
                  Join our community chat
                </p>
              </Link> */}
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default DiamonAccessPage;
