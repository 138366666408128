import { motion } from "framer-motion";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderImage from "../../components/HeaderImage";

const BotswanaSymposium: React.FC = () => {
  useEffect(() => {
    const element = document.getElementById("botswanasymposium");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  const speakers = [
    {
      name: "Hon. Dr. Douglas-Letsholathebe",
      position:
        "Minister of Tertiary Education and Skills Development, Botswana",
      thumbnail:
        "/assets/images/botswanasym/Hon.-Dr.-Douglas-Letsholathebe-600x680.png",
    },
    {
      name: "Prof. Nelson Torto",
      position:
        "Permanent Secretary, Ministry of Communications, Knowledge and Technology",
      thumbnail: "/assets/images/botswanasym/Nelson-Torto-01-600x680.png",
    },
    {
      name: "Dr. Martiale Zebaze Kana",
      position: "UNESCO Regional Office for Southern Africa",
      thumbnail: "/assets/images/botswanasym/Zebaze-Martiale-01-600x680.png",
    },
    {
      name: "Dr. Kostas Glinos",
      position: "Science & Technology policy expert",
      thumbnail: "/assets/images/experts/Kostas-Glinos.png",
    },
    {
      name: "Prof. Shedden Masupe",
      position: "CEO, Botswana Research and Education Network",
      thumbnail:
        "/assets/images/botswanasym/Professor-Shedden-Masupe-600x680.png",
    },
    {
      name: "Naniki Maphakwane",
      position: "Chair, Botswana Libraries Consortium",
      thumbnail: "/assets/images/experts/Naniki.png",
    },
    {
      name: "Mr. Tshepo Tsheko",
      position: "Acting CEO, Botswana Innovation Hub",
      thumbnail: "/assets/images/botswanasym/Mr.-Tshepo-Tsheko-600x680.png",
    },
    {
      name: "Prof. K. Moahi",
      position:
        "Deputy Vice Chancellor-Academic Services, Botswana Open University",
      thumbnail: "/assets/images/botswanasym/Prof.-K-Moahi-01-600x680.png",
    },
    {
      name: "Peter Mazebe II Mothataesi Sebina",
      position:
        "Deputy Dean, Faculty of Humanities, Library and Information Studies",
      thumbnail: "/assets/images/botswanasym/Mazape-600x680.png",
    },
    {
      name: "Iryna kuchma",
      position: "Open Access Programme Manager, EIFL",
      thumbnail: "/assets/images/experts/Iryna-Kuchma.png",
    },
    {
      name: "Vasilis Koulolias",
      position: "ICT advisor to the President",
      thumbnail: "/assets/images/botswanasym/Vasilis-01-600x680.png",
    },
    {
      name: "Omo Oaiya",
      position:
        "Chief Strategy Officer, West and Central African Research and Education Network (WACREN)",
      thumbnail: "/assets/images/ghanasym/Omo-2-01.jpg",
    },
    {
      name: "Dr. Ayanda LEBELE",
      position: "Director, Library & Information Services BIUST",
      thumbnail: "/assets/images/botswanasym/Lebele-01-600x680.png",
    },
    {
      name: "Jan Seanego",
      position:
        "Director - Library and Information Services at Botswana University of Agriculture and Natural Resources (BUAN)",
      thumbnail: "/assets/images/botswanasym/Jan-Seneago-01-600x680.png",
    },
    {
      name: "Poloko Ntokwane",
      position: "Manager-Knowledge Management and Dissemination (BIDPA)",
      thumbnail: "/assets/images/botswanasym/Poloko-01-600x680.png",
    },
    {
      name: "Radijeng Kgomotso",
      position:
        "Knowledge Commons Mgr., Botswana Institute for Tech Research & Innovation",
      thumbnail: "/assets/images/botswanasym/Radijeng-01-600x680.png",
    },
  ];

  const hosts = [
    {
      id: 1,
      thumbnail: "/assets/images/botswanasym/hosts/Botswana-MESRT-300x231.png",
    },
    {
      id: 2,
      thumbnail: "/assets/images/botswanasym/hosts/BotsREN-300x94.png",
    },
    {
      id: 3,
      thumbnail: "/assets/images/botswanasym/hosts/bih-2.jpg",
    },
    {
      id: 4,
      thumbnail:
        "/assets/images/botswanasym/hosts/307999337_401281242191816_6491253671080844452_n-300x298.png",
    },
  ];

  const partners = [
    {
      id: 1,
      thumbnail: "/assets/images/Wacren-Logo.jpg",
    },
    {
      id: 2,
      thumbnail: "/assets/images/ghanasym/partners/AC3-EU-01-300x184.jpg",
    },
    {
      id: 3,
      thumbnail:
        "/assets/images/botswanasym/partners/Screenshot-2022-11-03-at-14.11.59-300x111.png",
    },
    {
      id: 4,
      thumbnail:
        "/assets/images/botswanasym/partners/Botswana-Library-Association-300x245.png",
    },
    {
      id: 5,
      thumbnail:
        "/assets/images/botswanasym/partners/Botswana-Library-Services-300x83.jpg",
    },
  ];

  return (
    <>
      <HeaderImage image="/assets/images/backgrounds/botswanasym.jpg" />
      <div className="container mx-auto my-14" id="botswanasymposium">
        <section className="mb-12 text-center">
          <h4 className="text-left text-xl font-semibold text-foreground mb-4 mt-6">
            About the event
          </h4>
          <div className="text-left text-sm text-foreground">
            <p className="mb-4">
              With the establishment of the Botswana Research and Education
              Network (BotsREN), the NREN and the library community are looking
              to foster open science and open access to playing a critical role
              in the creation, management, discovery and reuse of scholarship.
            </p>
            <p className="mb-4">
              At the LIBSENSE Open Science Symposium – Botswana, key
              stakeholders spanning policy-makers, library communities of
              practice, researchers, social innovators and funders in Botswana
              gathered to discuss a wide array of open science matters,
              including infrastructure, policy and capacity building.
            </p>
            <p className="mb-4">
              Co-located with{" "}
              <Link
                to="https://ubuntunet.net/uc2022/"
                target="_blank"
                className="text-primary"
              >
                UbuntuNet Connect 2022
              </Link>
              , the Symposium was held in Gaborone at the{" "}
              <strong>
                Botswana Digital & Innovation Hub, Science and Technology Park
              </strong>{" "}
              on <strong>Wednesday, November 23, 2022</strong>.
            </p>

            <p className="mb-4">
              This fourth iteration of the LIBSENSE Open Science Roadmaps in
              Botswana sought to explore the following:
            </p>

            <ul className="text-sm text-foreground mb-4 list-disc">
              <li className="mb-2">
                Status of Open Science (OS), Open Access (OA) in Botswana
              </li>
              <li className="mb-2">
                Policy Framework on Open Access and Open Science
              </li>
              <li className="mb-2">
                Institutional Culture and Open Science/Open scholarship
              </li>
              <li className="mb-2">
                Building capacity to support open science, or investing in human
                resources
              </li>
            </ul>

            <p className="mb-4">
              The event was organised by{" "}
              <Link
                to="https://wacren.net"
                target="_blank"
                className="text-primary"
              >
                WACREN
              </Link>{" "}
              and jointly hosted by MCKT, MESD, Botswana Research and Education
              Network (BotsREN) and the Botswana Libraries Consortium (BLC).
            </p>
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h4 className="text-left text-xl font-medium text-foreground mb-4 mt-6">
            Speakers
          </h4>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {speakers &&
              speakers.map((item: any, index) => (
                <motion.div
                  key={item.name}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.name}
                      loading="lazy"
                      className="object-contain w-full h-48 rounded-md"
                    />
                  </div>
                  <div className="flex flex-col justify-between p-4">
                    <h3 className="text-sm font-semibold text-foreground mb-4">
                      {item.name}
                    </h3>
                    <p className="text-xs text-foreground">{item.position}</p>
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h3 className="text-center text-xl font-medium text-foreground mb-4 mt-6">
            Hosts
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {hosts &&
              hosts.map((item: any, index) => (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0 p-4">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.id}
                      loading="lazy"
                      className="object-contain w-full h-16 rounded-md"
                    />
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-28">
          <h3 className="text-center text-xl font-medium text-foreground mb-4 mt-6">
            Partners
          </h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {partners &&
              partners.map((item: any, index) => (
                <motion.div
                  key={item.id}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{
                    duration: 0.8,
                    ease: "easeOut",
                    delay: index * 0.2,
                  }}
                  whileHover={{
                    scale: 1.05,
                    boxShadow: "0px 10px 30px rgba(0, 0, 0, 0.2)",
                    transition: { duration: 0.3, ease: "easeInOut" },
                  }}
                  className="flex flex-col border rounded-lg shadow-md"
                >
                  <div className="w-full rounded-md flex-shrink-0 p-4">
                    <img
                      src={
                        item.thumbnail
                          ? item.thumbnail
                          : "/assets/images/logo.svg"
                      }
                      alt={item.id}
                      loading="lazy"
                      className="object-contain w-full h-16 rounded-md"
                    />
                  </div>
                </motion.div>
              ))}
          </div>
        </section>

        <section className="mb-4 mt-8">
          <h3 className="text-center text-lg font-semibold text-foreground mb-4">
            Resources
          </h3>
          <div className="flex justify-center space-x-4 mt-8">
            <Link
              to="https://indico.wacren.net/event/151/overview"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Event Page
            </Link>
            <Link
              to="https://www.youtube.com/watch?v=RIEpKfMqteA"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Video
            </Link>
            <Link
              to="https://photos.wacren.net/index.php?/category/45"
              target="_blank"
              className="block text-center border border-foreground mb-4 px-4 py-2 rounded-md text-foreground transition duration-300 hover:text-primary"
            >
              Photos
            </Link>
          </div>
        </section>
      </div>
    </>
  );
};

export default BotswanaSymposium;
