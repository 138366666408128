export const redirects = [
  { from: "/", to: "/home" },
  { from: "/en", to: "/home" },
  {
    from: "/en/open-science/institutional/",
    to: "/what-we-do/open-science-policy/institutional",
  },
  {
    from: "/en/open-science/national/",
    to: "/what-we-do/open-science-policy/national",
  },
  {
    from: "/en/open-science/regional/",
    to: "/what-we-do/open-science-policy/regional",
  },
  {
    from: "/en/open-science/regional-workshop-3/",
    to: "/what-we-do/open-science-policy/regional",
  },
  {
    from: "/en/open-science/regional-workshop-2/",
    to: "/what-we-do/open-science-policy/regional",
  },
  {
    from: "/en/open-science/regional-workshop-1/",
    to: "/what-we-do/open-science-policy/regional",
  },
  {
    from: "/en/open-science/libsense-and-dora-webinar/",
    to: "/what-we-do/open-science-policy/regional",
  },
  {
    from: "/en/open-science/continental/",
    to: "/what-we-do/open-science-policy/regional",
  },
  { from: "/en/news/", to: "/media/news" },
  { from: "/en/events/", to: "/media/events" },
  { from: "/en/support/", to: "/contact-us" },
  { from: "/en/network-of-experts/", to: "/network-of-experts" },
  { from: "/en/libsense-early-career-researcher-ecr-initiative/", to: "/ecr" },
  { from: "/en/ghana-ecr/", to: "/ecr-ghana" },
  { from: "/en/nigeria-ecr/", to: "/ecr-nigeria" },
  {
    from: "/en/diamond-open-access-community-of-practice/",
    to: "/diamond-open-access-community-of-practice",
  },
  {
    from: "/en/ghana-open-science-symposium/",
    to: "/ghana-open-science-symposium/",
  },
  {
    from: "/en/nigeria-open-science-symposium/",
    to: "/nigeria-open-science-symposium/",
  },
  {
    from: "/en/botswana-open-science-symposium/",
    to: "/botswana-open-science-symposium/",
  },
  {
    from: "/en/open-science/national/tunisia-open-science-symposium/",
    to: "/tunisia-open-science-symposium",
  },
  {
    from: "/en/open-science/national/sierraleone-open-science-symposium",
    to: "/sierraleone-open-science-symposium",
  },
  {
    from: "/en/support/",
    to: "/contact-us",
  },
  {
    from: "/en/about-us/",
    to: "/about-us",
  },
  {
    from: "/en/about-us/*",
    to: "/about-us#story",
  },
  {
    from: "/en/resources/",
    to: "/resources",
  },
  { from: "/en/resource/*", to: "/resources" },
  { from: "/en/resources/*", to: "/resources" },
  { from: "/en/member/*", to: "/home#partners" },
  { from: "/en/open-science/policy/", to: "/what-we-do/open-science-policy/" },
  { from: "/en/ruforum/", to: "/ruforum" },
  {
    from: "/en/connect/",
    to: "/about-us/project-initiatives#libsense-connect",
  },
];
