import React from "react";
import { Link } from "react-router-dom";

const RoadmapPage: React.FC = () => {
  const Flags = [
    {
      name: "Ghana",
      flag: "/assets/images/flags/ghana-flag.jpg",
      link: "/ghana-open-science-symposium",
      type: "internal",
    },
    {
      name: "Côte D’IVoire",
      flag: "/assets/images/flags/cote-d-ivoire-flag.png",
      link: "https://colloque.uvci.edu.ci/symposium/",
      type: "external",
    },
    {
      name: "Nigeria",
      flag: "/assets/images/flags/nigeria.jpg",
      link: "/nigeria-open-science-symposium",
      type: "internal",
    },
    {
      name: "Botswana",
      flag: "/assets/images/flags/flag-botswana-scaled.jpg",
      link: "/botswana-open-science-symposium/",
      type: "internal",
    },
    {
      name: "Tunisia",
      flag: "/assets/images/flags/tunisia.jpg",
      link: "/tunisia-open-science-symposium",
      type: "internal",
    },
    {
      name: "Sierra Leone",
      flag: "/assets/images/flags/sierra-leone-flag-scaled.jpg",
      link: "/sierraleone-open-science-symposium",
      type: "internal",
    },
  ];
  return (
    <>
      <div className="container mx-auto my-14">
        <p className="text-sm text-foreground mb-4">
          The UNESCO Open Science Partnership offers a strategic opportunity to
          advance open science in Africa by putting this on the agenda of
          national governments. We believe that the most effective approach to
          implementing open science is working at the national level, bringing
          together major stakeholders from a given country to develop and
          execute a national action plan for open science. Open science requires
          the development of several aspects concurrently:
        </p>
        <ul className="text-sm text-foreground mb-4">
          <li className="mb-2">
            1. Policies (which act as levers and incentives for researchers to
            practise open science)
          </li>
          <li className="mb-2">
            2. Infrastructure (to support the implementation of open science and
            sovereignty of national research outputs)
          </li>
          <li className="mb-2">
            3. Capacity (people to help provide guidance, run the
            infrastructure, etc.).
          </li>
        </ul>
        <div className="mb-4 border-l-2 border-primary">
          <p className="text-sm text-foreground ml-4">
            LIBSENSE works with several African countries committed to advancing
            open science policies, infrastructures and services to develop
            national Open Science Roadmaps that can then be adapted to other
            countries. These include Botswana, Côte d’Ivoire, Ethiopia, Ghana,
            Lesotho, Mozambique, Nigeria, Somalia, Tanzania and Uganda. In Côte
            d’Ivoire, Ghana, Nigeria and Uganda, the NRENs and Library Consortia
            are in advanced discussions towards National Open Science Shared
            Repository and Publishing Platforms.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-6 gap-1">
          {Flags.map((item, index) => (
            <div key={index} className="flex flex-col">
              <img
                className="w-[90%] h-full bg-cover"
                src={item.flag}
                alt={item.name}
              />
              <div className="flex items-center justify-center">
                {item.type === "internal" ? (
                  <Link
                    to={item.link}
                    className="bg-primary uppercase text-xs text-primary-foreground py-2 px-2 my-2"
                  >
                    {item.name}
                  </Link>
                ) : (
                  <Link
                    to={item.link}
                    target="_blank"
                    className="bg-primary text-xs uppercase text-primary-foreground py-2 px-2 my-2"
                  >
                    {item.name}
                  </Link>
                )}
              </div>
            </div>
          ))}
        </div>{" "}
        <div className="mb-4">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            Polices
          </h3>
          <p className="text-sm text-foreground mb-4">
            Open Science policies spell out the strategies and actions to
            promote the principles and practises of open science in a
            jurisdiction. These are contextual at the national level, shaped by
            local dynamics and nuances, including the cultural perception,
            political will or commitment to support open science and the general
            maturity level of the country's research, science and innovation
            ecosystem.
          </p>
          <div className="mb-4 border-l-2 border-primary">
            <p className="text-sm text-foreground ml-4">
              LIBSENSE provides a template for developing a national open
              science policy or contributing open science elements to an
              existing policy as a key step towards a national action plan.
            </p>
          </div>

          <Link
            to="https://baobab.wacren.net/records/mb2t3-5t628"
            target="_blank"
            className="block text-center border border-foreground mb-4 px-6 py-4 rounded-md text-foreground transition duration-300 hover:text-primary"
          >
            Download National Policy Template
          </Link>
        </div>
        <div className="mb-4">
          <h3 className="text-lg font-semibold text-foreground mb-4">
            Infrastructure
          </h3>
          <p className="text-sm text-foreground mb-4">
            Open Science requires open infrastructures supporting the research
            community in sharing their publications, data and software. LIBSENSE
            works with African countries to leverage NRENs where they exist in
            the development and long-term sustainability of such open
            infrastructures and promotes shared national platforms
          </p>
          <div className="mb-4 border-l-2 border-primary">
            <p className="text-sm text-foreground ml-4">
              LIBSENSE also fosters closer collaboration with local funding
              agencies, government frameworks and international agencies
              required to deploy and maintain these infrastructures.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RoadmapPage;
