import React from "react";

interface HeaderItem {
  image?: string;
  alt?: string;
}

const HeaderImage: React.FC<HeaderItem> = ({ image, alt }) => {
  const displayImage = image ? image : "/assets/images/hero.jpeg";
  const altTitle = alt ? alt : "header";
  return (
    <div className="mt-10 mx-24">
      <img
        src={`${displayImage}`}
        alt={`${altTitle}`}
        className={`w-full h-full lg:h-[70vh] ${image ? "object-fill md:object-contain" : "object-fill md:object-cover"}`}
      />
    </div>
  );
};

export default HeaderImage;
