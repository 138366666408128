const PolicyTypes = [
  { label: "Institutional OS", value: "Institutional OS" },
  { label: "National OS", value: "National OS" },
  { label: "Institutional OA", value: "Institutional OA" },
  { label: "National OA", value: "National OA" },
];
const PolicyElements = [
  {
    label: "Open Access Repositories For Publications",
    value: "open access repositories for publications",
  },
  {
    label: "Open Access Repositories For Data",
    value: "open access repositories for data",
  },
  {
    label: "Open Access Journals And Publishing Platforms",
    value: "open access journals and publishing platforms",
  },
  {
    label: "Supporting No-Fee OA Journals",
    value: "supporting no-fee OA journals",
  },
  {
    label: "Supporting OA Journals That Charge APCs",
    value: "supporting OA journals that charge APCs",
  },
  {
    label: "Other Open Science Infrastructures",
    value: "other open science infrastructures",
  },
  {
    label: "Recognising OA/Open Science In Research Assessment",
    value: "recognising OA/open science in research assessment",
  },
  {
    label: "Open Data For Government And Private Sector",
    value: "open data for government and private sector",
  },
];
const PolicyRequirements = [
  {
    label: "Depositing In A Repository Upon Publication",
    value: "depositing in a repository upon publication",
  },
  {
    label: "Immediate Open Access Under An Open Content License",
    value: "immediate open access under an open content license",
  },
  {
    label: "Delayed Open Access Under An Open Content License",
    value: "delayed open access under an open content license",
  },
  {
    label: "Publishing In OA Journals",
    value: "publishing in OA journals",
  },
  {
    label: "Making Available Scientific Outputs",
    value: "making available scientific outputs",
  },
  { label: "Rights Retention", value: "rights retention" },
  { label: "Depositing Preprints", value: "depositing preprints" },
  {
    label: "Open Access To Other Types Of Publications",
    value: "open access to other types of publications",
  },
];

export { PolicyTypes, PolicyElements, PolicyRequirements };
