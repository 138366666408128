import { useMatomo } from "@datapunt/matomo-tracker-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const useTrackPageView = () => {
  const location = useLocation();
  const { trackPageView } = useMatomo();

  useEffect(() => {
    trackPageView({ href: location.pathname });
  }, [location, trackPageView]);
};

export default useTrackPageView;
